import {
  makeObservable,
  computed,
  action,
  runInAction,
} from 'mobx';

import {
  getChecklistItems,
  getChecklistItem,
  createChecklistItem,
  updateChecklistItem,
  deleteChecklistItem,
} from '../services/checklistService';
import ToastStore from './ToastStore';
import { observable } from 'mobx';

interface IChecklistStore {
  state: StoreState;
  isLoading: boolean;
  checklistItems?: IChecklistItem[];
  singleChecklistItem?: IChecklistItem;
  checklistTotal: number;
  getChecklistItems: (params: IPaginationParams) => void;
  getChecklistItem: (id: string) => void;
  createChecklistItem: (checklistItem: IChecklistItem) => void;
  updateChecklistItem: (checklistItem: IChecklistItem) => void;
  deleteChecklistItem: (id: number) => void;
  resetSingleChecklistItem: () => void;
}


class ChecklistModel implements IChecklistStore {
  state: StoreState = 'Idle';
  checklistItems: IChecklistStore['checklistItems'] = undefined;
  singleChecklistItem: IChecklistStore['singleChecklistItem'] = undefined;
  checklistTotal: IChecklistStore['checklistTotal'] = 0;

  constructor() {
    makeObservable(this, {
      isLoading: computed,
      getChecklistItems: action,
      getChecklistItem: action,
      createChecklistItem: action,
      updateChecklistItem: action,
      deleteChecklistItem: action,
      checklistItems: observable,
      singleChecklistItem: observable,
      checklistTotal: observable,
      resetSingleChecklistItem: action,
    });
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  resetSingleChecklistItem = () => {
    this.singleChecklistItem = undefined;
  }
  /**
   * Returns the checklist item list
   */
  getChecklistItems = async (params: IPaginationParams) => {
    this.state = 'Loading';
    try {
      const response = await getChecklistItems(params);
      runInAction(() => {
        this.checklistItems = response.data.items;
        this.checklistTotal = response.data.total;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Returns a checklist item by id
   */
  getChecklistItem = async (id: string) => {
    this.state = 'Loading';
    try {
      const response = await getChecklistItem(id);
      runInAction(() => {
        this.singleChecklistItem = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  createChecklistItem = async (checklistItem: IChecklistItem) => {
    this.state = 'Loading';
    try {
      await createChecklistItem(checklistItem);

      ToastStore.showSuccess('successes.checklist.createChecklistItem');
      runInAction(() => {
        this.getChecklistItems({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.checklist.createChecklistItem');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Update checklist item
   */
  updateChecklistItem = async (checklistItem: IChecklistItem) => {
    this.state = 'Loading';

    try {
      await updateChecklistItem(checklistItem);
      ToastStore.showSuccess('successes.checklist.updateChecklistItem');
      runInAction(() => {
        this.getChecklistItems({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.checklist.updateChecklistItem');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Delete checklist item
   */
  deleteChecklistItem = async (id: number) => {
    this.state = 'Loading';
    try {
      await deleteChecklistItem(id);
      ToastStore.showSuccess('successes.checklist.deleteChecklistItem');
      runInAction(() => {
        this.getChecklistItems({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.checklist.deleteChecklistItem');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }
}

const ChecklistStore = new ChecklistModel();

export default ChecklistStore;
