import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { NavMenu } from '../NavMenu';

interface SideMenuProps {
  currentUserRoles?: string[];
}

const findRoute = (pathname: string) => {
  let route;
  const locationFirstPart = pathname.split('/')[1];
  for (let i = 0; i < ROUTES.length; i++) {
    if (locationFirstPart === ROUTES[i].href.split('/')[1]) {
      route = ROUTES[i];
      break;
    }
  }
  return route;
}

const SideMenu: FC<SideMenuProps> = ({ currentUserRoles = [] }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const currentRoute = findRoute(pathname);

  const filteredRoutes = ROUTES.filter((route) => {
    // filter out routes that user has no rights
    let hasRights = true;

    // filter out routes that are not menuItems
    return hasRights && route.menuItem;
  }).map((route) => ({
    href: route.href,
    label: t(`sidemenu.${route.name}`),
    isActive: route.menuGroup === currentRoute?.menuGroup,
    iconComponent: route.iconComponent,
    subItem: route.subItem,
  }));

  return (
    <NavMenu items={filteredRoutes} />
  );
}

export default SideMenu;
