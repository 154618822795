import { api, getPath, getAuthConfig } from './api';

const API_PATHS = {
  all: '/newsTemplate',
  single: '/newsTemplate/{itemId}',
}

export const getNewsTemplates = async (params?: IPaginationParams) => {
  return api.get(getPath(API_PATHS.all), getAuthConfig(false, params));
}

export const getNewsTemplate = async (id: string) =>
  api.get(getPath(API_PATHS.single, id), getAuthConfig());

export const createNewsTemplate = async (data: INewsTemplate) =>
  api.post(getPath(API_PATHS.all), data);

export const updateNewsTemplate = async (data: INewsTemplate) =>
  api.put(getPath(API_PATHS.single, data.id), data, getAuthConfig());

export const deleteNewsTemplate = async (id: number) =>
  api.delete(getPath(API_PATHS.single, id), getAuthConfig());
