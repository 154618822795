import { Box, Grid, Typography as T, Button } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import {
  TextField,
  makeValidate,
  makeRequired,
} from 'mui-rff';
import { Validator } from '../../utils/validation';
import { MINIMUM_PASSWORD_LENGTH } from '../../constants/siteSettings';

interface UserFormProps {
  initialValues?: IAdminUser;
  isLoading: boolean;
  isEdit: boolean;
  onCancel: () => void;
  onSubmit: (values: any, form: any, callback: any) => void;
};

export const UserForm: FC<UserFormProps> = ({ initialValues, isLoading, isEdit, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const schema = Validator.object().shape({
    firstName: Validator.string().max(255).required(),
    lastName: Validator.string().max(255).required(),
    email: Validator.string().email().max(255).required(),
    password: isEdit ? Validator.string() : Validator
      .string()
      .hasSpecialChars()
      .hasLowerCase()
      .hasUpperCase()
      .hasNumber()
      .min(MINIMUM_PASSWORD_LENGTH)
      .required(),

  });

  const validate = makeValidate(schema);
  const required = makeRequired(schema);

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      render={({ handleSubmit, values, submitting, pristine, hasValidationErrors, form: { change }, ...rest }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container>
              <Grid item >
                <Box>
                  <Grid spacing={3} container>
                    <Grid item xs={12}>
                      <Box>
                        <T variant="h2">{t(isEdit ? 'user.editUser' : 'user.addNewUser')}</T>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="firstName"
                        name="firstName"
                        label={t('user.firstName')}
                        required={required.firstName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="lastName"
                        name="lastName"
                        label={t('user.lastName')}
                        required={required.lastName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="email"
                        name="email"
                        label={t('user.email')}
                        required={required.email}
                      />
                    </Grid>
                    {!isEdit && (
                      <Grid item xs={12}>
                        <TextField
                          id="password"
                          type="password"
                          name="password"
                          label={t('user.password')}
                          required={required.password}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Box display="flex">
                        <Box mr={2}>
                          <Button
                            id="saveButton"
                            type="submit"
                            color="secondary"
                            disabled={hasValidationErrors}
                          >
                            {t('common.save')}
                          </Button>
                        </Box>
                        <Button
                          id="cancelButton"
                          variant="outlined"
                          onClick={onCancel}
                        >
                          {t('common.cancel')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </form>
        )
      }}
    />
  );
};
