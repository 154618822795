import { Box, Typography as T, Grid, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import { TextField, makeValidate, makeRequired } from "mui-rff";
import { Validator } from "../../utils/validation";
import { CommonModal } from "../../components/Modals";
import { FormFooter } from "../../components/FormControls/FormFooter";
import { observer } from "mobx-react-lite";
import { Wysiwyg } from "../../components/Wysiwyg/Wysiwyg";
import { useStores } from "../../stores/index";
import SelectAdapter from "../../components/FormControls/SelectAdapter";
import { required } from "../../utils";

interface PageContentModalProps {
  item?: IPageContent;
  isOpen: boolean;
  handleClose: any;
}

const useStyles = makeStyles({
  modalContent: {
    maxHeight: "calc(90vh - 100px)",
    overflow: "hidden",
    overflowY: "auto",
  },
});

export const PageContentModal: FC<PageContentModalProps> = observer(({ isOpen, item, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    pageContentStore: { createPageContent, updatePageContent, slugs },
  } = useStores();

  const schema = Validator.object().shape({
    title: Validator.string().max(255).required(),
    content: Validator.string().required(),
    title_en: Validator.string().max(255).nullable(),
    content_en: Validator.string().nullable(),
    title_sv: Validator.string().max(255).nullable(),
    content_sv: Validator.string().nullable(),
    ingress: Validator.string().nullable(),
    ingress_en: Validator.string().nullable(),
    ingress_sv: Validator.string().nullable(),
  });
  const validate = (values: any) => {
    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (err) {
      console.log("Validation result:", err);
      return makeValidate(schema)(values);
    }
    return {};
  };
  const required = (fieldName: string) => {
    const isRequired = makeRequired(schema)(fieldName);
    console.log("Field", fieldName, "is required:", isRequired);
    return isRequired;
  };

  const onSubmit = async (values: any, form: any) => {
    console.log("Submitted values:", values);
    
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err) {
      console.log("Validation errors:", err);
    }
  
    values.title_fi = values.title;
    values.ingress_fi = values.ingress;
    values.content_fi = values.content;
    if (item) {
      await updatePageContent(values);
    } else {
      await createPageContent(values);
    }
    form.initialize({});
    handleClose();
  };
  

  const title = item ? "pageContent.editItem" : "pageContent.addItem";

  const slugOptions = slugs?.map((slug) => ({
    id: slug,
    label: slug,
  }));

  return (
    <CommonModal open={isOpen} type="normal">
      <Form
        initialValues={item}
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit, ...rest }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Box className={classes.modalContent}>
                <Box mt={1} mb={1}>
                  <TextField name="title" label={t("common.title") + " (fi)"} required />
                </Box>
                <Box mt={1} mb={1}>
                  <TextField name="title_en" label={t("common.title") + " (en)"} />
                </Box>
                <Box mt={1} mb={1}>
                  <TextField name="title_sv" label={t("common.title") + " (sv)"} />
                </Box>
                <Box mt={1} mb={1}>
                  <SelectAdapter
                    name="slug"
                    label={t("common.slug")}
                    required
                    helperText={t("pageContent.slugHelper")}
                    options={slugOptions}
                  />
                </Box>
                <Box mt={1} mb={1}>
                  <TextField multiline name="ingress" label={t("pageContent.ingress") + " (fi)"} rows={5} />
                </Box>
                <Box mt={1} mb={1}>
                  <TextField multiline name="ingress_en" label={t("pageContent.ingress") + " (en)"} rows={5} />
                </Box>
                <Box mt={1} mb={1}>
                  <TextField multiline name="ingress_sv" label={t("pageContent.ingress") + " (sv)"} rows={5} />
                </Box>
                <Box mt={1} mb={1}>
                  <T color="textSecondary">{t("Sisältö suomeksi")}</T>
                  <Field
                    name="content"
                    render={({ input }) => {
                      return <Wysiwyg {...input} />;
                    }}
                  />
                </Box>
                <Box mt={1} mb={1}>
                  <T color="textSecondary">{t("Sisältö englanniksi")}</T>
                  <Field
                    name="content_en"
                    render={({ input }) => {
                      return <Wysiwyg {...input} />;
                    }}
                  />
                </Box>
                <Box mt={1} mb={1}>
                  <T color="textSecondary">{t("Sisältö ruotsiksi")}</T>
                  <Field
                    name="content_sv"
                    render={({ input }) => {
                      return <Wysiwyg {...input} />;
                    }}
                  />
                </Box>
              </Box>

              <FormFooter onCancel={handleClose} />
            </form>
          );
        }}
      />
    </CommonModal>
  );
});
