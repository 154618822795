import { AxiosError } from 'axios';
import { apiURL } from './api';
import AuthStore from '../stores/AuthStore';
import ToastStore from '../stores/ToastStore';

const errorHandler = (error: AxiosError) => {
  // if has response show the error
  // if (error.message?.includes('timeout')) {
  //   ToastStore.showError('errors.common.timeoutExceeded');
  // }

  if (error.response) {
    const { response: { status }, config: { url } } = error;
    // @ts-ignore
    const path = url?.replace(apiURL, '');

    switch (status) {
      // If backend throws 401, we will remove the token and redirect user to login page
      case 401:
        if (path !== '/auth/login') {
          AuthStore.logout();
        }
        break;
      case 400:
        break;
      case 429:
        ToastStore.showError('errors.http.429');
        break;
      case 500:
        break;
      case 480:
        break;
      case 404:
        // TODO: let's squelch these for awhile
        break;
      default:
        break;
    }
  }
  // if (msg) ToastStore.showError(msg);
  throw error;
};

export default errorHandler;
