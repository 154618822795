/* eslint-disable react/prop-types */
import React, { FC } from "react";
import { Box, Button, ButtonProps, makeStyles } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import colors from "../../styles/colors";

const ButtonBase = {
  height: "32px",
  width: "32px",
  padding: 0,
  fontSize: "12px",
  minWidth: 0,
  "& svg": {
    height: 18,
  },
  color: colors.grey7,
};
const useStyles = makeStyles({
  paginationgButton: ButtonBase,
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 18,
    "&  button:not(:last-child)": {
      marginRight: 8,
    },
  },
  activeButton: {
    ...ButtonBase,
    ...{
      color: colors.white,
      backgroundColor: colors.grey6,
    },
  },
});

interface PaginationProps {
  gotoPage: (pageIndex: number) => void;
  totalCount: number;
  pageSize: number;
  page: number;
}

interface PaginationButtonProps extends ButtonProps {
  active?: boolean;
}

const PaginationgButton: FC<PaginationButtonProps> = ({ children, active, ...rest }) => {
  const classes = useStyles();
  const { activeButton, paginationgButton } = classes;
  return (
    <Button variant="outlined" className={active ? activeButton : paginationgButton} {...rest}>
      {children}
    </Button>
  );
};

const Pagination: FC<PaginationProps> = ({ gotoPage, totalCount, pageSize, page }) => {
  const totalPageCount = Math.ceil(totalCount / pageSize);

  const classes = useStyles();

  const onNextClick = () => {
    gotoPage(page + 1);
  };

  const onPreviousClick = () => {
    gotoPage(page - 1);
  };

  const onPageClick = (pageIndex: number) => {
    gotoPage(pageIndex);
  };

  const getPages = () => {
    const pages = [];
    for (let i = 0; i < totalPageCount; i += 1) {
      pages.push(i);
    }
    return pages;
  };

  if (totalPageCount < 2) return null;

  return (
    <Box className={classes.buttonContainer}>
      <PaginationgButton onClick={onPreviousClick} disabled={page === 0}>
        <ChevronLeft />
      </PaginationgButton>
      {getPages().map((pageIndex) => {
        return (
          <PaginationgButton key={pageIndex} onClick={() => onPageClick(pageIndex)} active={page === pageIndex}>
            {pageIndex + 1}
          </PaginationgButton>
        );
      })}
      <PaginationgButton onClick={onNextClick} disabled={page === totalPageCount - 1}>
        <ChevronRight />
      </PaginationgButton>
    </Box>
  );
};

export default Pagination;
