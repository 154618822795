import { api, getPath, getAuthConfig } from './api';

const API_PATHS = {
  all: '/feedback',
  single: '/feedback/{itemId}',
}

export const getFeedbacks = async (params: IPaginationParams) => {
  return api.get(getPath(API_PATHS.all), getAuthConfig(false, params));
}

export const getSingleFeedback = async (id: string) =>
  api.get(getPath(API_PATHS.single, id), getAuthConfig());

export const deleteFeedback = async (id: number) =>
  api.delete(getPath(API_PATHS.single, id), getAuthConfig());
