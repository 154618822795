import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Typography as T, Box, Button } from '@material-ui/core';
import { Layout } from '../../components/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { CommonModal } from '../../components/Modals/CommonModal';
import { useStores } from '../../stores';
import { ActionTableCell } from '../../components/ActionTableCell/ActionTableCell';
import { FaqListModal } from './FaqListModal';

export interface FaqListsScreenProps {

}

export const FaqListsScreen: FC<FaqListsScreenProps> = observer(() => {
  const PAGE_SIZE = 10;
  const {
    faqStore: {
      getFaqList, getFaqLists, deleteFaqList, faqLists, singleFaqList, faqListTotal,
      resetSingleFaqList, getFaqItems, getSlugs,
    },
  } = useStores();

  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [deleteFaqListModalOpen, setDeleteFaqListModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchFaqLists = useCallback(async (pageIndex?: number) => {
    const page = pageIndex === undefined ? currentPage : pageIndex;
    const skip = PAGE_SIZE * page;
    return getFaqLists({ take: PAGE_SIZE, skip });
  }, [getFaqLists, currentPage]);

  useEffect(() => {
    if (!initialized) {
      fetchFaqLists();
      getFaqItems();
      getSlugs();
      setInitialized(true);
    }
  }, [fetchFaqLists, initialized, getFaqItems, getSlugs]);

  const handleDeleteClick = useCallback(() => (id: string) => {
    getFaqList(id);
    setDeleteFaqListModalOpen(true);
  }, [getFaqList, setDeleteFaqListModalOpen]);

  const handleEditClick = useCallback(() => async (id: string) => {
    await getFaqList(id);
    setDetailsOpen(true);
  }, [getFaqList, setDetailsOpen]);

  const handleDeleteConfirm = async () => {
    if (singleFaqList?.id) {
      await deleteFaqList(singleFaqList.id);
    }
    setDeleteFaqListModalOpen(false);
    fetchFaqLists();
  };

  const handleModalClose = () => {
    resetSingleFaqList();
    setDetailsOpen(false);
    setDeleteFaqListModalOpen(false);
  }

  const onPageChange = async (pageIndex: number) => {
    if (pageIndex !== currentPage) {
      setCurrentPage(pageIndex);
      fetchFaqLists(pageIndex);
    }
  };

  const handleAddClick = () => {
    resetSingleFaqList();
    setDetailsOpen(true);
  };

  const headerButtons = (
    <Button
      variant="outlined"
      startIcon="+"
      onClick={handleAddClick}
    >{t('faq.addNewFaqList')}</Button>
  );

  const COLUMNS = useMemo(() => {
    const cols = [{
      accessor: 'slug',
      Header: t('faq.slug.slug'),
      width: '90%',
      Cell: ({ cell }: any) => (
        <Box>
          {t(`faq.slug.${cell.value}`)}
        </Box>
      ),
    }, {
      accessor: 'id',
      Header: '',
      Cell: ({ cell }: any) => (
        <ActionTableCell
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          params={cell.value}
        />
      ),
    }];
    return cols;
  }, [handleEditClick, handleDeleteClick, t]);

  const renderDeleteNewsModal = () => (
    <CommonModal
      open={deleteFaqListModalOpen}
      type="prompt"
      title={t('faq.deleteFaqListConfirm')}
      confirmText={t('common.remove')}
      onConfirm={handleDeleteConfirm}
      onCancel={handleModalClose}
    >
      <T>{t('faq.deleteFaqListConfirmBody')}</T>
    </CommonModal>
  );

  return (
    <Layout headerText={t('faq.faqLists')} headerElementsRight={headerButtons}>
      <Box>
        <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
          <T color="textSecondary">{t('faq.listCount', { count: faqListTotal })}</T>
        </Box>
        <MaterialTable
          columns={COLUMNS}
          data={faqLists}
          onPageChange={onPageChange}
          totalCount={faqListTotal}
          paginationEnabled
        />
      </Box>
      {detailsOpen && <FaqListModal isOpen={detailsOpen} item={singleFaqList} handleClose={() => setDetailsOpen(false)} />}
      {renderDeleteNewsModal()}
    </Layout>
  );
});
