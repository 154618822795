import React, { FC, useState, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../stores/index';
import { CommonModal } from '../../CommonModal';
import { UserForm } from '../../../../screens/Users/UserForm';
import { Tab, Tabs, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PasswordForm } from '../../../../screens/Users/PasswordForm';

interface EditUserModalProps {
  open: boolean;
  isLoading: boolean;
  user?: IAdminUser;
  onSubmit: (values: any, form: any, cb: any) => void;
  onCancel: () => void;
};

export const EditUserModal: FC<EditUserModalProps> = observer(({
  open,
  isLoading,
  user,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const {
    authStore: { currentUser },
  } = useStores();
  const [activeTab, setActiveTab] = useState(0);

  const onTabChange = (event: ChangeEvent<{}>, value: any) => {
    setActiveTab(value);
  };

  const isCurrentUser = currentUser?.id === user?.id;

  const renderUserForm = () => (
    <UserForm
      initialValues={user}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isLoading={isLoading}
      isEdit
    />
  )

  const renderUserAndPasswordForm = () => {
    return (
      <Box>
        <Box mb={5}>
          <Tabs
            value={activeTab}
            onChange={onTabChange}
            TabIndicatorProps={{
              style: { background: '#fff', }
            }}
          >
            <Tab label={t('user.basicInformation')} />
            <Tab label={t('user.changePassword')} />
          </Tabs>
        </Box>
        {activeTab === 0 && renderUserForm()}
        {activeTab === 1 && <PasswordForm isLoading={isLoading} onCancel={onCancel} />}
      </Box>
    );
  };
  return (
    <CommonModal
      type="normal"
      open={open}
    >
      {isCurrentUser ? renderUserAndPasswordForm() : renderUserForm()}
    </CommonModal>
  )

});
