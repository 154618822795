const RED = '#D32029';
const GREEN = '#49AA19';
const BLACK = '#111111';
const WHITE = '#ffffff';
const GREY1 = '#141414';
const GREY2 = '#1d1d1d';
const GREY3 = '#262626';
const GREY4 = '#303030';
const GREY5 = '#434343';
const GREY6 = '#5A5A5A';
const GREY7 = '#7D7D7D';
const GREY8 = '#ACACAC';
const GREY9 = '#dbdbdb';

const PRIMARY = '#0071BA';
const PRIMARY2 = '#005993';
const PRIMARY3 = '#0b4368';
const PRIMARY4 = '#0c3855';

const colors = {
  black: BLACK,
  white: WHITE,
  grey1: GREY1,
  grey2: GREY2,
  grey3: GREY3,
  grey4: GREY4,
  grey5: GREY5,
  grey6: GREY6,
  grey7: GREY7,
  grey8: GREY8,
  grey9: GREY9,
  primary: PRIMARY,
  primary2: PRIMARY2,
  primary3: PRIMARY3,
  primary4: PRIMARY4,
  secondary: PRIMARY3,
  background: GREY1,
  backgroundLight: GREY2,
  green: GREEN,
  red: RED,
  blue3: '#15395B',
  geekblue3: '#1C2755',
  red3: '#58181C',
  text: BLACK,
};

export default colors;
