import { api, getPath, getAuthConfig } from './api';

const API_PATHS = {
  settings: '/settings'
}

export const updateSettings = async (data: UpdateSettingsDTO) => {
  return api.put(getPath(API_PATHS.settings), data, getAuthConfig());
}

export const getSettings = async () => {
  return api.get(getPath(API_PATHS.settings), getAuthConfig());
}
