import { Box, Grid, Typography as T, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import { TextField, makeValidate } from "mui-rff";
import { Validator } from "../../utils/validation";
import { CommonModal } from "../../components/Modals";
import { FormFooter } from "../../components/FormControls/FormFooter";
import { observer } from "mobx-react-lite";
import { Wysiwyg } from "../../components/Wysiwyg/Wysiwyg";
import { useStores } from "../../stores/index";

interface ChecklistModalProps {
  item?: IChecklistItem;
  isOpen: boolean;
  handleClose: any;
}

const useStyles = makeStyles({
  content: {
    overflowY: "auto",
    maxHeight: "calc(90vh - 100px)",
    overflowX: "hidden",
  },
});

export const ChecklistModal: FC<ChecklistModalProps> = observer(({ isOpen, item, handleClose }) => {
  const { t } = useTranslation();
  const {
    checklistStore: { createChecklistItem, updateChecklistItem },
  } = useStores();
  const classes = useStyles();
  const schema = Validator.object().shape({
    title: Validator.string().required(),
    content: Validator.string().required(),
    href: Validator.string(),
    linkText: Validator.string(),
  });

  const validate = makeValidate(schema);

  const onSubmit = async (values: any) => {
    values.title_fi = values.title;
    values.content_fi = values.title;
    if (item) {
      await updateChecklistItem(values);
    } else {
      await createChecklistItem(values);
    }
    handleClose();
  };

  const title = item ? "checklist.editItem" : "checklist.addItem";

  return (
    <Form
      initialValues={item}
      validate={validate}
      onSubmit={onSubmit}
      render={({ handleSubmit, values, submitting, pristine, hasValidationErrors, form: { change }, ...rest }) => {
        return (
          <CommonModal open={isOpen} type="normal">
            <Box>
              <T variant="h2">{t(title)}</T>
              <form onSubmit={handleSubmit} noValidate>
                <Box className={classes.content}>
                  <Box mt={1} mb={1}>
                    <TextField name="title" label={t("common.title") + " (fi)"} required />
                  </Box>
                  <Box mt={1} mb={1}>
                    <TextField name="title_en" label={t("common.title") + " (en)"} />
                  </Box>
                  <Box mt={1} mb={1}>
                    <TextField name="title_sv" label={t("common.title") + " (sv)"} />
                  </Box>
                  <Box my={3}>
                  <T color="textSecondary">{t("Sisältö suomeksi")}</T>
                    <Field
                      name="content"
                      render={({ input }) => {
                        return <Wysiwyg {...input} />;
                      }}
                    />
                  </Box>
                  <Box my={3}>
                  <T color="textSecondary">{t("Sisältö englanniksi")}</T>
                    <Field
                      name="content_en"
                      render={({ input }) => {
                        return <Wysiwyg {...input} />;
                      }}
                    />
                  </Box>
                  <Box my={3}>
                  <T color="textSecondary">{t("Sisältö ruotsiksi")}</T>
                    <Field
                      name="content_sv"
                      render={({ input }) => {
                        return <Wysiwyg {...input} />;
                      }}
                    />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField name="href" label={t("checklist.href")} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField name="linkText" label={t("checklist.linkText")} />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <FormFooter onCancel={handleClose} />
              </form>
            </Box>
          </CommonModal>
        );
      }}
    />
  );
});
