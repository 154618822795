import React, { FC } from 'react';
import { Grid, Box, Typography, makeStyles, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores';
import { Layout } from '../../components/Layout';
import LoginForm from './LoginForm';
import colors from '../../styles/colors';

interface LoginScreenProps {
}

const useStyles = makeStyles({
  fullHeight: {
    height: '100%',
    minHeight: '100vh',
  },
  center: {
    textAlign: 'center',
  },
  formContainer: {
    height: '100%',
    maxWidth: '960px',
    backgroundColor: colors.white,
  },
  imageContainer: {
    background: "url('/images/loginbg.png') no-repeat",
    height: '100%',
    minHeight: '100vh',
    maxWidth: '960px',
  },
});

export const LoginScreen: FC<LoginScreenProps> = observer(() => {
  const { login, isLoading } = useStore('authStore');
  const { t } = useTranslation();
  const classes = useStyles();

  const onLoginSubmit = async (formValues: any) => {
    await login(formValues);
  };

  return (
    <Layout>
      <Grid container justify="center" className={classes.fullHeight}>
        <Grid item xs={6} className={classes.formContainer}>
          <Grid container justify="center" alignItems="center" className={classes.fullHeight}>
            <Grid item xs={12} md={8} lg={6}>
              <Container>
                <Box mb={4} className={classes.center}>
                  <Typography variant="h4">{t('login.heading')}</Typography>
                </Box>
                <Box mb={4} className={classes.center}>
                  <Typography variant="h1">{t('login.login')}</Typography>
                </Box>
                <Box>
                  <LoginForm onSubmit={onLoginSubmit} isLoading={isLoading} />
                </Box>
                {/* <Box mt={4} className={classes.center}>
                  <Link to="/forgot-password">{t('login.forgotPasswordLink')}</Link>
                </Box> */}
              </Container>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.imageContainer}>
        </Grid>
      </Grid>
    </Layout>
  );
});
