import { Box, Typography, Grid, FormLabel, Tabs, Tab, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useMemo, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import {
  makeValidate,
} from 'mui-rff';
import { Validator } from '../../utils/validation';
import { CommonModal } from '../../components/Modals';
import { FormFooter } from '../../components/FormControls/FormFooter';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../stores/index';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { HtmlContentCell } from '../../components/HtmlContentCell/HtmlContentCell';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableContainer } from '../../components/Draggable/DraggableContainer';
import SelectAdapter from '../../components/FormControls/SelectAdapter';

interface FaqListModalProps {
  item?: IFaqList;
  isOpen: boolean;
  handleClose: any;
};

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '50vh',
    overflowY: 'auto',
    border: '1px solid #ddd',
  },
  content: {
    overflowY: 'auto',
    maxHeight: 'calc(90vh - 100px)',
    overflowX: 'hidden',
  },
});

export const FaqListModal: FC<FaqListModalProps> = observer(({ isOpen, item, handleClose }) => {
  const [selectedIds, setSelectedIds] = useState<any>();
  const [activeTab, setActiveTab] = useState(0);
  const [items, setItems] = useState<IFaqItem[] | []>([]);
  const { t } = useTranslation();
  const classes = useStyles();
  const { faqStore: {
    createFaqList, updateFaqList, faqItems, updateFaqOrder, getFaqList, singleFaqList,
    slugs,
  } }: {
    faqStore: IFaqStore;
  } = useStores();

  const schema = Validator.object().shape({
    slug: Validator.string().required(),
  });

  const validate = makeValidate(schema);

  const onSubmit = async (values: any, form: any, woot: any) => {
    if (activeTab === 0) {
      values.faqs = selectedIds.map((id: string) => parseInt(id, 10));
      if (item) {
        await updateFaqList(values)
      } else {
        await createFaqList(values)
      }
      form.initialize({});
    } else {
      const data = items.map((item: IFaqItem, index: number) => ({
        id: item.id,
        ordinal: index,
      }));
      await updateFaqOrder({ items: data });
    }
    handleClose();

    await getFaqList(item?.id as any);
    if (singleFaqList) setItems(singleFaqList?.faqs)
  };

  useEffect(() => {
    if (item?.faqs) setItems(item.faqs);
  }, [item, setItems]);

  const onListChange = (items: IFaqItem[]) => {
    setItems(items);
  };

  useEffect(() => {
    if (item && item.faqs && !selectedIds) {
      setSelectedIds(item.faqs.map((faq) => String(faq.id)));
    }
  }, [item, setSelectedIds, selectedIds]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedIds(undefined);
    }
  }, [isOpen]);

  const title = item ? 'faq.editList' : 'faq.addList';

  const COLUMNS = useMemo(() => {
    const cols = [{
      accessor: 'title',
      Header: t('common.title'),
      width: '25%',
    }, {
      accessor: 'content',
      Header: t('common.content'),
      width: '75%',
      Cell: ({ row: { original } }: any) => {
        return <HtmlContentCell content={original.content} maxWidth={500} />
      },
    }];
    return cols;
  }, [t]);

  const onRowSelect = (ids: string[]) => {
    setSelectedIds(ids);
  };
  const slugOptions = slugs?.map((slug) => ({
    id: slug,
    label: t(`faq.slug.${slug}`),
  }));

  const renderMain = () => (
    <>
      <Box my={3}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SelectAdapter
              name="slug"
              label={t('faq.slug.slug')}
              options={slugOptions}
              helperText={t('faq.slugHelper')}
              required
            />
          </Grid>
        </Grid>
      </Box>

      <Box mb={3}>
        <FormLabel>{t('faq.selectItems')}</FormLabel>
      </Box>

      <Box className={classes.tableContainer}>
        {(!item || selectedIds) && (<MaterialTable
          data={faqItems}
          columns={COLUMNS}
          onRowSelect={onRowSelect}
          selectedIds={selectedIds}
        />
        )}
      </Box>
    </>
  );

  const renderOrderSection = () => item && (
    <Box mt={3}>
      <DndProvider backend={HTML5Backend}>
        <DraggableContainer items={item.faqs} textAccessor="title" onChange={onListChange} />
      </DndProvider>
    </Box>
  );

  const onTabChange = (e: any, tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const TABS = ['main', 'order'];

  return (
    <Form
      initialValues={item}
      validate={validate}
      onSubmit={onSubmit}
      render={({ handleSubmit, values, submitting, pristine, hasValidationErrors, form: { change }, ...rest }) => {
        return (
          <CommonModal open={isOpen} type="normal">
            <Box>
              <Typography variant="h2">{t(title)}</Typography>
              <form onSubmit={handleSubmit} noValidate>
                <Box className={classes.content}>
                  {item && (
                    <Tabs onChange={onTabChange} value={activeTab}>
                      {TABS.map((tab: string) => <Tab label={t(`faq.tabs.${tab}`)} />)}
                    </Tabs>
                  )}
                  {activeTab === 0 && renderMain()}
                  {activeTab === 1 && renderOrderSection()}
                </Box>
                <FormFooter
                  onCancel={handleClose}
                  saveText={activeTab === 0 ? 'faq.saveList' : 'faq.saveOrder'}
                  cancelText={t('common.close')}
                />
              </form>
            </Box >
          </CommonModal >

        )
      }}
    />
  );
});
