/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import moment from 'moment';

/**
 * Takes in a ISO date and returns it in a pretty form
 */
export const getPrettyDate = (
  date?: string, time?: boolean, clockAbbr?: string, includeDay?: boolean,
) => {
  if (!date) return null;

  const format = includeDay ? 'dd L' : 'D.M.y';
  let dateStr = moment(date).format(format);
  dateStr = dateStr.charAt(0).toUpperCase() + dateStr.substring(1);
  if (!time) return dateStr;

  const timeStr = moment(date).format('HH:mm');
  return `${dateStr} ${clockAbbr} ${timeStr}`;
};

export const getPrettyTimePeriod = (startDate: string, endDate: string) => `${getPrettyDate(startDate)}–${getPrettyDate(endDate)}`;

export const getPrettySum = (sum: number, includeCurrency?: boolean) => {
  const resolvedSum = `${(sum / 100).toFixed(2).replace('.', ',')}`;
  return includeCurrency ? `${resolvedSum}€` : resolvedSum;
};
