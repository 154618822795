import { Box, Typography as T, Button, makeStyles, FormLabel, Grid, Divider } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react-lite';
import { CommonModal } from '../../components/Modals/CommonModal';

interface FeedbackModalProps {
  item?: IFeedback;
  onClose: () => void;
};

const useStyles = makeStyles({
  content: {
    overflowY: 'auto',
    maxHeight: 'calc(90vh - 100px)',
    overflowX: 'hidden',
  },
  userLabel: {
    fontWeight: 'bold',
  },
});

export const FeedbackModal: FC<FeedbackModalProps> = observer(({
  item, onClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const userFields = ['firstName', 'lastName', 'phoneNumber', 'email'];

  return (
    <CommonModal open type="normal" width={800}>
      <Box>
        <T variant="h2">{t('common.feedback')}</T>

        <Grid container>
          {item && userFields.map((userField) => (
            <Grid item xs={3}>
              <FormLabel className={classes.userLabel}>{t(`feedback.user.${userField}`)}</FormLabel>
              {/* @ts-ignore */}
              <T>{item[userField]}</T>
            </Grid>
          ))}
        </Grid>

        <Box my={3}>
          <Divider />
        </Box>
        <Box className={classes.content}>
          {item?.message && <div dangerouslySetInnerHTML={{ __html: item?.message }} />}
        </Box>

        <Box display="flex" mt={3}>
          <Button onClick={onClose} variant="outlined">{t('common.cancel')}</Button>
        </Box>
      </Box>
    </CommonModal>

  );
});
