import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';
import { Layout } from '../../components/Layout';
import { Form } from 'react-final-form';
import { Switches, TextField } from 'mui-rff';
import { useStores } from '../../stores/index';

interface SettingsScreenProps { }

export const SettingsScreen: FC<SettingsScreenProps> = observer(() => {
  const { t } = useTranslation();
  const { settingsStore: { getSettings, updateSettings, settings } } = useStores();

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const onSubmit = (values: any) => {
    updateSettings(values);
  };

  return (
    <Layout headerText={t('settings.settings')}>
      <Box p={3}>
        <Form
          initialValues={settings || {}}
          onSubmit={onSubmit}
          render={({ handleSubmit, dirty, values, invalid }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Typography variant="h2">Huoltotila</Typography>
                <Box mb={3}>
                  <Switches
                    data={[{ value: true, label: t('settings.maintenanceModeOn') }]}
                    name="maintenanceMode"
                  />
                  <Box mb={2} />
                  <TextField disabled={!values.maintenanceMode} name="maintenanceTitle" label={t('settings.maintenanceModeTitle')}></TextField>
                  <Box mb={2} />
                  <TextField disabled={!values.maintenanceMode} rows={8} multiline name="maintenanceMessage" label={t('settings.maintenanceModeMessage')}></TextField>
                </Box>
                <Button disabled={!dirty} type="submit">{t('common.save')}</Button>
              </form>
            );
          }}
        />
      </Box>
    </Layout >
  );
});
