import { Box, Grid, Typography as T, Button, makeStyles, Typography } from "@material-ui/core";
import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import SelectAdapter from "../../components/FormControls/SelectAdapter";
import { TextField, makeValidate, makeRequired, Checkboxes } from "mui-rff";
import { Validator } from "../../utils/validation";
import { Wysiwyg } from "../../components/Wysiwyg/Wysiwyg";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/index";
import { DateTimePicker } from "../../components/DateTimePicker";
import { CommonModal } from "../../components/Modals/CommonModal";
import { NewsFilters } from "../../components/NewsFilters";

interface NewsModalProps {
  item?: INews | INewsTemplate;
  handleClose: any;
  isCreatingFromTemplate: boolean;
}

const useStyles = makeStyles({
  content: {
    overflowY: "auto",
    maxHeight: "calc(90vh - 100px)",
    overflowX: "hidden",
  },
});

export const NewsModal: FC<NewsModalProps> = observer(({ item, handleClose, isCreatingFromTemplate }) => {
  const { t } = useTranslation();
  const {
    newsStore: { createNews, updateNews, getNews },
  } = useStores();
  const classes = useStyles();

  const schema = Validator.object().shape({
    title: Validator.string().max(255).required(),
    content: Validator.string().required(),
    title_fi: Validator.string().max(255).nullable(),
    content_fi: Validator.string().nullable(),
    title_en: Validator.string().max(255).nullable(),
    content_en: Validator.string().nullable(),
    title_sv: Validator.string().max(255).nullable(),
    content_sv: Validator.string().nullable(),
    visibleFrom: Validator.date().nullable(),
    visibleTo: Validator.date()
      .nullable()
      .when("visibleFrom", (visibleFrom: Date, schema: any) => {
        if (visibleFrom) {
          return schema.min(visibleFrom, () => t("validation.startDateBeforeEndDate"));
        }
        return schema;
      }),
  });

  const validate = makeValidate(schema);
  const validateWithDefaults = (values: any) => {
    if (!values.visibleFrom) {
      values.visibleFrom = new Date();
    }
    return validate(values);
  };
  const required = makeRequired(schema);

  const onSubmit = async (values: any) => {
    values.title_fi = values.title;
    values.content_fi = values.content;
    if (item && !isCreatingFromTemplate) {
      await updateNews(values);
    } else {
      await createNews(values);
    }
    await getNews({ take: 10, skip: 0 });
    handleClose();
  };

  const initialValues = item || { isDraft: true, visibleFrom: new Date() };

  return (
    <CommonModal open type="normal">
      <Form
        initialValues={initialValues}
        validate={validateWithDefaults}
        onSubmit={onSubmit}
        render={({ handleSubmit, values, submitting, pristine, hasValidationErrors, form: { change }, ...rest }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <T variant="h2">{t(item ? "news.editNews" : "news.addNewNews")}</T>

              <Box className={classes.content}>
                <TextField id="title" name="title" label={t("news.title")} required={required.title} />
                  <Box mt={1} mb={1}>
                    <TextField id="title_en" name="title_en" label={t("news.title_en")} />
                  </Box>
                  <Box mt={1} mb={1}>
                    <TextField id="title_sv" name="title_sv" label={t("news.title_sv")} />
                  </Box>
                <Box mt={3}>
                  <NewsFilters />
                </Box>
                <Box mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <DateTimePicker label={t("news.visibleFrom")} name="visibleFrom" />
                    </Grid>
                    <Grid item xs={4}>
                      <DateTimePicker label={t("news.visibleTo")} name="visibleTo" />
                    </Grid>
                    <Grid item xs={4}>
                      <Box display="flex" alignItems="center" height="100%">
                        <Checkboxes
                          name="isExceptionNotice"
                          data={{ label: t("news.exceptionNotice"), value: "isExceptionNotice" }}
                        />
                        <Checkboxes name="isDraft" data={{ label: t("news.draft"), value: "isDraft" }} />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={4}>
                  <Typography>{t("news.exceptionNoticeInfo")}</Typography>
                </Box>

                  <Box mt={2}>
                    <T color="textSecondary">{t("Sisältö suomeksi")}</T>
                    <Field
                      name="content"
                      render={({ input }) => (
                        <Wysiwyg {...input} />
                      )}
                    />
                  </Box>
                  <Box mt={2}>
                    <T color="textSecondary">{t("Sisältö englanniksi")}</T>
                    <Field
                      name="content_en"
                      render={({ input }) => (
                        <Wysiwyg {...input} />
                      )}
                    />
                  </Box>
                  <Box mt={2}>
                    <T color="textSecondary">{t("Sisältö ruotsiksi")}</T>
                    <Field
                      name="content_sv"
                      render={({ input }) => (
                        <Wysiwyg {...input} />
                      )}
                    />
                  </Box>
              </Box>
              <Box display="flex" mt={3}>
                <Box mr={1}>
                  <Button type="submit">{t("common.save")}</Button>
                </Box>
                <Button onClick={handleClose} variant="outlined">
                  {t("common.cancel")}
                </Button>
              </Box>
            </form>
          );
        }}
      />
    </CommonModal>
  );
});
