

import { api, getPath, getAuthConfig } from './api';

const API_PATHS = {
  owners: 'customers/admin/owners',
  regions: 'customers/admin/regions',
  estates: 'customers/admin/estates',
}

export const getOwners = async () =>  {
  const res = await api.get(getPath(API_PATHS.owners), getAuthConfig());
  return res?.data?.owners;
};

export const getEstates = async () =>  {
  const res = await api.get(getPath(API_PATHS.estates), getAuthConfig());
  return res?.data?.estates;
};

export const getRegions = async () =>  {
  const res = await api.get(getPath(API_PATHS.regions), getAuthConfig());
  return res?.data?.regions;
};
