import React from 'react';
import CommonStore from './CommonStore';
import ToastStore from './ToastStore';
import AuthStore from './AuthStore';
import UserStore from './UserStore';
import NewsStore from './NewsStore';
import NewsTemplateStore from './NewsTemplateStore';
import YhDataStore from './YhDataStore';
import FeedbackStore from './FeedbackStore';
import ChecklistStore from './ChecklistStore';
import FaqStore from './FaqStore';
import PageContentStore from './PageContentStore';
import SettingsStore from './SettingsStore';


export const stores = Object.freeze({
  commonStore: CommonStore,
  toastStore: ToastStore,
  authStore: AuthStore,
  userStore: UserStore,
  newsStore: NewsStore,
  newsTemplateStore: NewsTemplateStore,
  yhDataStore: YhDataStore,
  feedbackStore: FeedbackStore,
  faqStore: FaqStore,
  checklistStore: ChecklistStore,
  pageContentStore: PageContentStore,
  settingsStore: SettingsStore,
});

export const storesContext = React.createContext(stores);
export const StoresProvider = storesContext.Provider;
