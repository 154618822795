import React from 'react';
// @ts-ignore
import { BrowserRouter as Router } from 'react-router-dom';
import 'typeface-inter';
import { initLang } from './i18n';
import Routes from './Routes';

initLang('fi');
const App = () => {

  return (
    <div className="App">
      <Router>
        <Routes />
      </Router>
    </div>
  );
};

export default App;
