import React, { FC, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Typography as T, Box, Button } from '@material-ui/core';
import { Layout } from '../../components/Layout';
import { CommonModal } from '../../components/Modals/CommonModal';
import { useStores } from '../../stores';
import { NewsTemplateModal } from './NewsTemplateModal';
import { NewsTemplateTable } from './NewsTemplateTable';

export interface NewsTemplateScreenProps {

}

export const NewsTemplateScreen: FC<NewsTemplateScreenProps> = observer((props: NewsTemplateScreenProps) => {
  const {
    newsTemplateStore: {
      getNewsTemplate, newsTemplate, deleteNewsTemplate,
      resetNewsTemplate, newsTemplateTotal, getNewsTemplates,
    },
  } = useStores();

  const { t } = useTranslation();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [deleteNewsModalOpen, setDeleteNewsTemplateModalOpen] = useState(false);


  const handleDeleteClick = useCallback(() => (id: string) => {
    getNewsTemplate(id);
    setDeleteNewsTemplateModalOpen(true);
  }, [getNewsTemplate, setDeleteNewsTemplateModalOpen]);

  const handleEditClick = useCallback((id: string) => async (id: string) => {
    await getNewsTemplate(id);
    setDetailsOpen(true);
  }, [getNewsTemplate, setDetailsOpen]);

  const getTemplates = () => getNewsTemplates({ take: 10, skip: 0 })


  const handleDeleteConfirm = async () => {
    if (newsTemplate?.id) {
      await deleteNewsTemplate(newsTemplate.id);
    }
    setDeleteNewsTemplateModalOpen(false);
    getTemplates();
  };

  const handleModalClose = () => {
    setDetailsOpen(false);
    setDeleteNewsTemplateModalOpen(false);
  }


  const handleAddClick = () => {
    resetNewsTemplate();
    setDetailsOpen(true);
  };

  const addButton = (
    <Box>
      <Button
        variant="outlined"
        startIcon="+"
        onClick={handleAddClick}
      >{t('newsTemplate.addNewNewsTemplate')}</Button>
    </Box>
  );

  const renderDeleteNewsModal = () => (
    <CommonModal
      open={deleteNewsModalOpen}
      type="prompt"
      title={t('newsTemplate.deleteNewsTemplateConfirm')}
      confirmText={t('common.remove')}
      onConfirm={handleDeleteConfirm}
      onCancel={handleModalClose}
    >
      <T>{t('newsTemplate.deleteNewsTemplateConfirmBody')}</T>
    </CommonModal>
  );

  return (
    <Layout headerText={t('newsTemplate.newsTemplates')} headerElementsRight={addButton}>
      <Box>
        <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
          <T color="textSecondary">{t('newsTemplate.count', { count: newsTemplateTotal })}</T>
        </Box>
        <NewsTemplateTable onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} itemsEditable />
      </Box>
      {detailsOpen && <NewsTemplateModal isOpen={detailsOpen} item={newsTemplate} handleClose={() => setDetailsOpen(false)} />}
      {renderDeleteNewsModal()}
    </Layout>
  );
});
