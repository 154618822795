import {
  makeObservable,
  action,
  runInAction,
} from 'mobx';

import {
  getEstates,
  getRegions,
  getOwners,
} from '../services/yhDataService';
import ToastStore from './ToastStore';
import { observable } from 'mobx';

interface IYhDataStore {
  estates?: IEstate[];
  getEstates: () => void;
  owners?: IOwner[];
  getOwners: () => void;
  regions?: IRegion[];
  getRegions: () => void;
}


class YhDataModel implements IYhDataStore {
  state: StoreState = 'Idle';
  estates: IYhDataStore['estates'] = undefined;
  regions: IYhDataStore['regions'] = undefined;
  owners: IYhDataStore['owners'] = undefined;

  constructor() {
    makeObservable(this, {
      getEstates: action,
      getRegions: action,
      getOwners: action,
      estates: observable,
      regions: observable,
      owners: observable,
    });
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  getEstates = async () => {
    this.state = 'Loading';
    try {
      const response = await getEstates();
      runInAction(() => {
        this.estates = response;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
        ToastStore.showError('errors.yhData.getEstates')
      });
      throw error;
    }
  }

  getRegions = async () => {
    this.state = 'Loading';
    try {
      const response = await getRegions();
      runInAction(() => {
        this.regions = response;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
        ToastStore.showError('errors.yhData.getRegions')
      });
      throw error;
    }
  }

  getOwners = async () => {
    this.state = 'Loading';
    try {
      const response = await getOwners();
      runInAction(() => {
        this.owners = response;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
        ToastStore.showError('errors.yhData.getOwners')
      });
      throw error;
    }
  }
}

const YhDataStore = new YhDataModel();

export default YhDataStore;
