import { observable, action, makeObservable } from 'mobx';
import Toast from './Toast';

const SUCCESS_TIMEOUT = 5000;
class ToastModel {
  toasts: IToast[] = [];

  constructor() {
    makeObservable(this, {
      toasts: observable,
      showSuccess: action,
      showError: action,
      closeToast: action,
      closeToasts: action,
    });
  }

  showSuccess = (msg: string, values?: any) => {
    const index = this.toasts.length ? this.toasts.length - 1 : 0;
    const newToasts = this.toasts.slice();
    const newToast = new Toast(msg, 'success', index, values);
    newToasts.push(newToast);
    this.toasts = newToasts;

    // Removes toast after SUCCESS_TIMEOUT
    setTimeout(() => {
      this.toasts = this.toasts.filter((t) => t.id !== newToast.id);
    }, SUCCESS_TIMEOUT);
  };

  showError = (msg: string, values?: any) => {
    const index = this.toasts.length ? this.toasts.length - 1 : 0;
    if (this.toasts.length > 2) this.toasts.pop();
    this.toasts.push(new Toast(msg, 'error', index, values));
  }

  closeToast = (id: string) => {
    this.toasts = this.toasts.filter((t) => t.id !== id);
  };

  closeToasts = () => {
    this.toasts = [];
  };
}

const ToastStore = new ToastModel();
export default ToastStore;
