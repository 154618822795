import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useStores } from '../../stores';
import colors from '../../styles/colors';
import SideMenu from './SideMenu';
import TopBar from './TopBar';
import theme from '../../styles/theme';
import Spinner from './MainSpinner';
interface LayoutProps {
  headerText?: string;
  headerElementsRight?: ReactNode;
  headerElementsBottom?: ReactNode;
  headerElementsTop?: ReactNode;
}

const useStyles = makeStyles({
  layout: {
    background: colors.grey9,
    height: '100%',
    minHeight: '100vh',
    alignItems: 'stretch',
  },
  content: {
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  sidebar: {
    backgroundColor: colors.primary2,
  },
  logoContainer: {
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: '27px',
    marginBottom: '1rem',
  },
  pageHeader: {
    height: 75,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  pageHeaderMainContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pageHeaderWrapper: {
    backgroundColor: colors.white,
    padding: theme.spacing(3),
    borderTop: `1px solid ${colors.grey9}`,

  },
  headerElements: {
    display: 'flex',
    width: 'auto',
    minWidth: 0,
    justifyContent: 'space-between',
  },
  headerElementsRight: {
    display: 'flex',
    width: 'auto',
    minWidth: 0,
    justifyContent: 'flex-end',
  },
  contentContainer: {
    flex: '1 1 auto',
    minHeight: 'auto',
    height: 'auto',
    margin: theme.spacing(3),
    backgroundColor: colors.white,
    position: 'relative',
    boxShadow: '1px 1px 5px rgba(0,0,0,0.)',
    borderRadius: '5px',
    padding: theme.spacing(3),
  },
  headerText: {
  },
});

export const Layout: FC<LayoutProps> = observer(({
  headerText, headerElementsRight, headerElementsTop, headerElementsBottom,
  children,
}) => {
  const {
    authStore: {
      currentUser,
      logout,
    },
  } = useStores();
  const classes = useStyles();

  const renderNonAuthContent = () => {
    return (
      <Grid id="content" item xs={12} className={classes.content}>
        {children}
      </Grid>
    );
  };



  const renderAuthContent = () => {
    return (
      <>
        <Grid id="sidebar" item xs={2} sm={3} md={2} className={classes.sidebar}>
          <Box>
            <div className={classes.logoContainer}>
              <img src="/images/yh-kodit.png" alt="YH Kodit" className={classes.logo} />
            </div>
            <SideMenu />
          </Box>
        </Grid>

        <Grid id="content" item xs={10} sm={9} md={10} className={classes.content}>
          <TopBar currentUser={currentUser} onLogout={logout} />
          {headerText && (
            <div className={classes.pageHeaderWrapper}>
              <div className={classes.pageHeader}>
                {headerElementsTop}
                <Box className={classes.pageHeaderMainContent}>
                  <Typography className={classes.headerText} variant="h1">{headerText}</Typography>
                  {headerElementsRight && (
                    <div className={classes.headerElements}>
                      <div className={classes.headerElementsRight}>
                        {headerElementsRight}
                      </div>
                    </div>
                  )}
                </Box>
              </div>
              {headerElementsBottom}
            </div>
          )}
          <div className={classes.contentContainer}>
            <Spinner />
            {children}
          </div>
        </Grid>
      </>
    );
  };

  return (
    <Grid id="layout" container className={classes.layout}>
      {currentUser ? renderAuthContent() : renderNonAuthContent()}
    </Grid>
  );
});
