import { Box, IconButton } from '@material-ui/core';
import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

interface ActionTableCellProps {
  onEditClick: any;
  onDeleteClick: any;
  params?: any;
}

export const ActionTableCell: FC<ActionTableCellProps> = ({ onEditClick, onDeleteClick, params }) => {
  const style = { width: 16, height: 16 };
  const handleEditClick = () => {
    onEditClick()(params);
  }
  const handleDeleteClick = () => {
    onDeleteClick()(params);
  }
  return (
    <Box display="flex">
      <Box>
        <IconButton onClick={handleEditClick}><FontAwesomeIcon style={style} icon={faEdit} /></IconButton>
      </Box>
      <IconButton onClick={handleDeleteClick}><FontAwesomeIcon style={style} icon={faTrashAlt} /></IconButton>
    </Box>
  );
};
