import {
  makeObservable,
  computed,
  action,
  runInAction,
} from 'mobx';

import {
  getFeedbacks,
  getSingleFeedback,
  deleteFeedback,
} from '../services/feedbackService';
import ToastStore from './ToastStore';
import { observable } from 'mobx';

interface IFeedbackStore {
  state: StoreState;
  isLoading: boolean;
  feedbacks?: IFeedback[];
  feedbacksTotal: number;
  singleFeedback?: IFeedback;
  getFeedbacks: (params: IPaginationParams) => void;
  getSingleFeedback: (id: string) => void;
  deleteFeedback: (id: number) => void;
}


class FeedModel implements IFeedbackStore {
  state: StoreState = 'Idle';
  feedbacks: IFeedbackStore['feedbacks'] = undefined;
  singleFeedback: IFeedbackStore['singleFeedback'] = undefined;
  feedbacksTotal: IFeedbackStore['feedbacksTotal'] = 0;

  constructor() {
    makeObservable(this, {
      isLoading: computed,
      getFeedbacks: action,
      getSingleFeedback: action,
      deleteFeedback: action,
      feedbacks: observable,
      singleFeedback: observable,
      feedbacksTotal: observable,
    });
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  /**
   * Returns the Feedback list
   */
  getFeedbacks = async (params: IPaginationParams) => {
    this.state = 'Loading';
    try {
      const response = await getFeedbacks(params);
      runInAction(() => {
        this.feedbacks = response.data.items;
        this.feedbacksTotal = response.data.total;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Returns a single Feedback by id
   */
  getSingleFeedback = async (id: string) => {
    this.state = 'Loading';
    try {
      const response = await getSingleFeedback(id);
      runInAction(() => {
        this.singleFeedback = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }


  /**
   * Delete feedback
   */
  deleteFeedback = async (id: number) => {
    this.state = 'Loading';
    try {
      await deleteFeedback(id);
      ToastStore.showSuccess('successes.Feedback.deleteFeedback');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.Feedback.deleteFeedback');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }
}

const FeedbackStore = new FeedModel();

export default FeedbackStore;
