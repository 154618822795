import React, { FC } from 'react';
import { CommonModal } from '../../components/Modals';
import { observer } from 'mobx-react-lite';
import { NewsTemplateTable } from './NewsTemplateTable';
import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface NewsTemplateListModalProps {
  onCloseClick: any;
  onOpenTemplateClick: (template: INewsTemplate) => void;
};

export const NewsTemplateListModal: FC<NewsTemplateListModalProps> = observer(({
  onCloseClick, onOpenTemplateClick,
}) => {
  const { t } = useTranslation();
  return (
    <CommonModal open={true} type="normal">
      <>
        <Typography variant="h2">{t('newsTemplate.selectTemplate')}</Typography>
        <NewsTemplateTable onOpenClick={onOpenTemplateClick} />
        <Box mt={3}>
          <Button onClick={onCloseClick}>{t('common.close')}</Button>
        </Box>
      </>
    </CommonModal>
  );
});
