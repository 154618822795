import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import theme from '../../styles/theme';

interface Props extends IToast {
  index: number;
  onClose: (id: string) => void;
}

const useStyles = makeStyles({

  alert: {
    marginBottom: theme.spacing(2),
    zIndex: 5,
  },
});

const Toast: FC<Props> = ({
  onClose, type, msg, values, id,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const onCloseClick = () => {
    onClose(id);
  };
  return (
    <Alert className={classes.alert} severity={type} onClose={onCloseClick}>{t(msg, values)}</Alert>
  );
};

export { Toast };
