import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, List, ListItem, makeStyles} from '@material-ui/core';
import { Layout } from '../../components/Layout';

interface LinksScreenProps { }

export const LinksScreen: FC<LinksScreenProps> = observer(() => {
  const { t } = useTranslation();
  
  const useStyles = makeStyles({
    typo: {
      width: '200px',
      height: '50px', 
      marginRight: 100,
      borderRadius: 12,
      fontSize: 16,
      color: 'black',
      fontWeight: 600,
      textAlign: 'left',
      paddingTop: 15
    },
  
    copy: {
      width: '100px',
      height: '50x',
    },

  });
  const classes = useStyles()
  const LinkList = () => {
    const links = [
      { name: 'ajankohtaiset-tiedotteet', text: 'Ajankohtaiset tiedotteet' },
      { name: 'asumisen-ohjeet', text: 'Asumisen ohjeet' },
      { name: 'kiinteiston-tiedot', text: 'Kiinteiston tiedot' },
      { name: 'turvallisuus', text: 'Turvallisuus' },
      { name: 'kestava-arki', text: 'Kestava arki' },
    ];
  
    async function handleClick(link: string) {
      try {
          await navigator.clipboard.writeText(`/fi/living#${link}`);
          console.log('Link copied to clipboard');
      } catch (err) {
          console.error('Failed to copy text: ', err);
      }
    }

    return (
      <List>
        {links.map((link) => (
          <ListItem key={link.name}>
              <Typography className={classes.typo}>
                {link.text}
              </Typography>
              <Button className={classes.copy} onClick={() => handleClick(link.name)}>
                Copy
              </Button>
          </ListItem>
        ))}
      </List>
    );
  }
  return (
    <Layout headerText={t('settings.links')}>
        <Box p={3}>
        <Typography variant='h2'> Sisällönsyöttöä varten kopioitavat kiinteät linkit Aumisen ohjeet-haitarielementin otsikoihin:</Typography>
        {LinkList()}
        </Box>
    </Layout >
  );
});