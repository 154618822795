import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { NavMenuItem } from './NavMenuItem';

export type NavMenuItemType = {
  href: string;
  label: string;
  isActive: boolean;
  iconComponent?: any;
  subItem?: boolean;
};
export interface NavMenuProps {
  items: NavMenuItemType[];
}

const useStyles = makeStyles({
  menuList: {
    width: '100%',
    listStyle: 'none',
    paddingLeft: 0,
  },
});

export const NavMenu: FC<NavMenuProps> = ({ items }) => {
  const classes = useStyles();
  return (
    <ul className={classes.menuList}>
      {items.map((item) => <NavMenuItem key={item.href} item={item} />)}
    </ul>
  )
};
