/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, MouseEventHandler } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import colors from '../../styles/colors';
import theme from '../../styles/theme';


interface TopBarProps {
  currentUser?: IAdminUser;
  onLogout: () => void;
}

const textContainer = {
  marginRight: '14px',
  '& .iconContainer': {
    margin: '14px 7px 14px 14px',
    padding: '6px 17px 6px 10px',
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    color: colors.white,
    backgroundColor: colors.primary,
    lineHeight: '22px',
  },
  '& .buttonText': {
    lineHeight: '60px',
    color: colors.primary,
  },

};
const useStyles = makeStyles({
  container: {
    backgroundColor: colors.white,
    height: '60px',
    color: colors.primary,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  buttonContainer: {
    ...textContainer, ...{
      '&:hover': {
        cursor: 'pointer',
        '& .iconContainer': {
          color: colors.primary,
          backgroundColor: colors.grey3,
        },
        '& .buttonText': {
          color: colors.primary,
        },
      },
    }
  },
  textContainer,
});


const TopBar: FC<TopBarProps> = observer(({ currentUser, onLogout }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderGridItem = (type: 'button' | 'text', text: string, icon: any, clickCallback?: MouseEventHandler<HTMLDivElement>) => {
    const styles = type === 'button' ? classes.buttonContainer : classes.textContainer;
    return (
      <Grid item className={styles} onClick={clickCallback!}>
        <Grid container direction="row" justify="flex-end">
          <Grid item className="iconContainer">
            <FontAwesomeIcon icon={icon} />
          </Grid>
          <Grid item>
            <Typography className="buttonText">{text}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container direction="row" justify="flex-end" className={classes.container}>
        {renderGridItem('text', currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : '', faUser)}

        {renderGridItem('button', t('common.logout'), faSignOutAlt, onLogout)}
      </Grid>
    </>
  );
});

export default TopBar;
