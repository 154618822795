import { api, getPath, getAuthConfig } from './api';

const API_PATHS = {
  all: '/pageContent',
  single: '/pageContent/{itemId}',
  slugs: '/pageContent/slugs',
  asset: '/asset/create',
}

export const createAsset = async (name: string, content: any) => {
  const formData = new FormData();
  formData.append('file', content);
  formData.append('name', name);
  formData.append('preview', '');
  return api.post(getPath(API_PATHS.asset), formData, { headers: { 'content-type': 'multipart/form-data' } });
}


export const getPageContents = async (params: IPaginationParams) => {
  return api.get(getPath(API_PATHS.all), getAuthConfig(false, params));
}

export const getPageSlugs = async () => {
  return api.get(getPath(API_PATHS.slugs), getAuthConfig());
}

export const getPageContent = async (id: string) =>
  api.get(getPath(API_PATHS.single, id), getAuthConfig());

export const createPageContent = async (data: IPageContent) =>
  api.post(getPath(API_PATHS.all), data);

export const updatePageContent = async (data: IPageContent) =>
  api.put(getPath(API_PATHS.single, data.id), data, getAuthConfig());

export const deletePageContent = async (id: number) =>
  api.delete(getPath(API_PATHS.single, id), getAuthConfig());
