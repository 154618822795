import React, { FC, ReactNode } from 'react';
import { Field } from 'react-final-form'
import { makeStyles, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface LegacyInputProps {
  id: string;
  name: string;
  t: string;
  validate?: any;
  type?: string;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  icon?: ReactNode;
}

const useStyles = makeStyles({
  iconLabel: {
    paddingLeft: '0.5rem',
  },
});

export const LegacyInput: FC<LegacyInputProps> = ({
  validate, name, id, t, required, icon, ...rest
}) => {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const label = icon ? <span>{icon}<span className={classes.iconLabel}>{translate(t)}</span></span> : <span>{translate(t)}</span>;
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <TextField
          {...input}
          id={id}
          label={label}
          fullWidth
          required={required}
          error={meta.touched && !!meta.error}
          helperText={(meta.touched && meta.error) ? translate(meta.error) : ' '}
          {...rest}
        />
      )}
    </Field>
  );
}
