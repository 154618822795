import React, { FC } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { NavMenuItemType } from './NavMenu';
import theme from '../../styles/theme';
import colors from '../../styles/colors';
// @ts-ignore
import { useHistory } from 'react-router-dom';

interface NavMenuItemProps {
  item: NavMenuItemType;
}

const useStyles = makeStyles({
  listItemContainer: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    paddingRight: theme.spacing(1),
    color: colors.white,
    width: '30px',
  },
  menuItem: {
    cursor: 'pointer',
    backgroundColor: colors.primary2,
    '&:hover': {
      backgroundColor: colors.primary,
    },
  },
  subItem: {
    backgroundColor: colors.primary3,
    paddingLeft: '1.8rem',
    cursor: 'pointer',
  },
  menuItemActive: {
    cursor: 'pointer',
    backgroundColor: colors.primary3,
    '& .MuiTypography-button': {
      fontWeight: 600,
    },
  },
  itemText: {
    fontSize: '1rem',
    color: colors.white,
  },
});

export const NavMenuItem: FC<NavMenuItemProps> = ({ item }) => {
  const classes = useStyles();
  const history = useHistory();

  const liClass = item.isActive ? classes.menuItemActive : classes.menuItem;

  const onListItemClick = () => {
    history.push(item.href);
  };

  if (item.subItem && !item.isActive) return null;

  if (item.subItem) {
    return (
      <li onClick={onListItemClick} className={classes.subItem}>
        <Box px={3} paddingTop={2} paddingBottom={2} className={classes.listItemContainer}>
          <Typography variant="button" className={classes.itemText}>{item.label}</Typography>
        </Box>
      </li>
    );
  }

  return (
    // @ts-ignore
    <li onClick={onListItemClick} className={liClass}>
      <Box px={1} paddingTop={2} paddingBottom={2} className={classes.listItemContainer}>
        {item.iconComponent ? <Box className={classes.iconContainer}>{item.iconComponent}</Box> : null}
        <Typography variant="button" className={classes.itemText}>{item.label}</Typography>
      </Box>
    </li>
  );
}
