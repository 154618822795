import {
  storeAutoSave,
} from '../utils';
import { observable, action, makeObservable } from 'mobx';
import { ROUTES } from '../constants';
import AuthStore from './AuthStore';


export interface ICommonStore {
  authToken: string;
  state: StoreState;
  currentRoutes: IRoute[];
  isLoading: boolean;
  updateAuthToken: (token: string) => void;
  updateCurrentRoutes: () => void;
}

class CommonModel implements ICommonStore {
  state: StoreState = 'Idle';
  authToken: string = '';
  currentRoutes: IRoute[] = [];

  constructor() {
    makeObservable(this, {
      authToken: observable,
      updateAuthToken: action,
      updateCurrentRoutes: action,
    });

    // initial value setup must be placed here before update by autoSave
    this.authToken = '';

    storeAutoSave(this, 'commonStore', ['currentRoutes', 'documents']);
  }


  get isLoading() {
    return this.state === 'Loading';
  }

  /**
   * Updates the auth token used in http requests
   */
  updateAuthToken = (authToken: string) => {
    this.authToken = authToken;
  };

  /**
   * Updates current routes by logged in user role
   */
  updateCurrentRoutes = () => {
    this.currentRoutes = ROUTES.filter((route) => {
      // If not authenticated, filter routes that requires authentication
      if (!AuthStore.currentUser) {
        return !!route.nonAuth;
      }
      // If authenticated, filter routes that are for non authenticated users
      if (route.nonAuth) return false;
      let ok = true;

      return ok;
    });
  };
}

const CommonStore = new CommonModel();

export default CommonStore;

