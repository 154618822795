import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { Select } from 'mui-rff';
import { Chip, SelectProps } from '@material-ui/core';
import { MenuItem, Box } from '@material-ui/core';
import { toJS } from 'mobx';


interface SelectAdapterProps extends SelectProps {
  name: string;
  validate?: any;
  label?: string;
  instruction?: string;
  helperText?: string;
  p?: number;
  options?: { id: string | number; label: string }[];
  format?: any;
  multiple?: boolean;
  renderChipLabel?: (value: string) => string
};

const SelectAdapter: FC<SelectAdapterProps> = ({ renderChipLabel, name, validate, options, instruction, children, multiple, ...props }) => {
  const getValue = (val: any) => {
    let tVal = toJS(val);
    if (typeof tVal === 'string') tVal = tVal.split(',')
    return multiple && !Array.isArray(tVal) ? [tVal] : tVal;
  }


  return (
    <Field name={name} validate={validate} render={({ p, input, meta }) => {
      const removeValue = (val: string) => {
        let newVal;
        if (typeof input.value === 'string') {
          newVal = '';
        } else {
          newVal = input.value.filter((arrayValue: string) => arrayValue !== val)
        }
        input.onChange(newVal);
      };

      const renderChip = (val: string) => {
        if (!renderChipLabel) return null;
        return (
          <Box p="2px">
            <Chip label={renderChipLabel(val)} onDelete={() => removeValue(val)} />
          </Box>
        )
      }
      return (
        <Box>
          <Select
            p={p}
            multiple={multiple}
            {...input}
            {...props}
            value={getValue(input.value)}
          >
            {children}
            {options && options.map((opt) => (<MenuItem key={opt.id} value={opt.id}>{opt.label}</MenuItem>))}
          </Select>
          {multiple && renderChip && <Box mt={1}>{getValue(input.value).map((val: any) => val && renderChip(val))}</Box>}
        </Box>
      )
    }}
    />
  );
};

export default SelectAdapter;
