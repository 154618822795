import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Typography as T, Button, Box } from '@material-ui/core';
import { Layout } from '../../components/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { CommonModal } from '../../components/Modals/CommonModal';
import { useStores } from '../../stores';
import { UserModal } from './UserModal';
import { ActionTableCell } from '../../components/ActionTableCell';

export interface UsersScreenProps {

}

export const UsersScreen: FC<UsersScreenProps> = observer((props: UsersScreenProps) => {
  const {
    userStore: {
      users,
      user,
      getUserList,
      getUser,
      resetUser,
      deleteUser,
    },
  } = useStores();

  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!initialized) {
      getUserList();
      setInitialized(true);
    }
  }, [getUserList, initialized, setInitialized]);

  const [detailsOpen, setDetailsOpen] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);

  const handleAddClick = () => {
    resetUser();
    setDetailsOpen(true)
  };

  const onDeleteClick = useCallback((id: string) => () => {
    getUser(id);
    setDeleteUserModalOpen(true);
  }, [getUser, setDeleteUserModalOpen]);

  const onEditClick = useCallback(() => async (id: string) => {
    await getUser(id);
    setDetailsOpen(true)
  }, [getUser]);



  const handleDeleteConfirm = async () => {
    if (user?.id) {
      await deleteUser(user.id);
    }
    setDeleteUserModalOpen(false);
    getUserList();
  };

  const handleCancelClick = () => {
    setDetailsOpen(false);
    setDeleteUserModalOpen(false);
  }
  const addButton = (
    <Box>
      <Button
        id="adduserButton"
        variant="outlined"
        startIcon="+"
        onClick={handleAddClick}
      >{t('user.addNewUser')}</Button>
    </Box>
  );



  const COLUMNS = useMemo(() => {
    const cols = [{
      accessor: 'firstName',
      Header: t('user.firstName'),
    }, {
      accessor: 'lastName',
      Header: t('user.lastName'),
    }, {
      accessor: 'email',
      Header: t('user.email'),
    }, {
      accessor: 'id',
      Header: '',
      Cell: ({ cell }: any) => (
        <ActionTableCell
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          params={cell.value}
        />
      ),
    }];
    return cols;
  }, [onEditClick, onDeleteClick, t]);

  const renderDeleteUserModal = () => (
    <CommonModal
      open={deleteUserModalOpen}
      type="prompt"
      title={t('user.deleteUserConfirm')}
      confirmText={t('common.remove')}
      onConfirm={handleDeleteConfirm}
      onCancel={handleCancelClick}
    >
      <T>{`${user?.firstName} ${user?.lastName}`}</T>
    </CommonModal>
  );

  return (
    <Layout
      headerText={t('sidemenu.users')}
      headerElementsRight={addButton}
    >
      <Box>
        <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
          <T color="textSecondary">{t('user.userCount', { count: users?.length || 0 })}</T>
        </Box>
        <MaterialTable
          columns={COLUMNS}
          data={users}
        />
      </Box>
      <UserModal isOpen={detailsOpen} item={user} handleClose={() => setDetailsOpen(false)} />
      {renderDeleteUserModal()}
    </Layout>
  );
});
