import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/inter';
import { initLang } from './i18n/index';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { StoresProvider, stores } from './stores/Stores';
import theme from './styles/theme';
import App from './App';
import { ToastProvider } from './components/Toast';
import moment from 'moment';
import fiDateLocale from 'date-fns/locale/fi';
import DateFnsUtils from '@date-io/date-fns';

initLang('fi');
moment.lang('fi');

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <StoresProvider value={stores}>
        <MuiPickersUtilsProvider locale={fiDateLocale} utils={DateFnsUtils}>
          <CssBaseline />
          <App />
          <ToastProvider />
        </MuiPickersUtilsProvider>
      </StoresProvider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
