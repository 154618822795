import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fi from './locales/fi.json';
import en from './locales/en.json';
import 'moment/locale/fi';
import moment from 'moment';


export const languages = [{ name: 'Suomi', value: 'fi' }];

export let momentInstance = moment.locale('fi');
export const initLang = async (lng: string) => {
  await i18n.use(initReactI18next).init({
    fallbackLng: languages[0].value,
    lng,
    resources: {
      fi: { translation: fi },
      en: { translation: en },
    },
  });

  moment.locale('fi');
  return i18n;
};
