import React, { FC } from 'react';
import { KeyboardDateTimePickerProps } from "@material-ui/pickers";
import { KeyboardDateTimePicker } from 'mui-rff';
import { useTranslation } from 'react-i18next';

export interface DateTimePickerProps extends Omit<KeyboardDateTimePickerProps, 'name' | 'onChange' | 'value'> {
  name: string;
}

export const DateTimePicker: FC<DateTimePickerProps> = (props) => {
  const { t } = useTranslation();
  return (
    <KeyboardDateTimePicker
      {...props}
      format={`dd.MM.yyyy, HH:mm`}
      ampm={false}
      cancelLabel={t('common.cancel')}
    />
  );
};
