import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import SelectAdapter from '../FormControls/SelectAdapter';
import { useStores } from '../../stores/index';
import { Grid, CircularProgress, Box } from '@material-ui/core';

interface NewsFiltersProps {
  hideChips?: boolean;
  selectSingle?: boolean;
  statusFilter?: boolean;
};

export const NewsFilters: FC<NewsFiltersProps> = observer(({ hideChips, selectSingle, statusFilter }) => {
  const { t } = useTranslation();
  const {
    yhDataStore: { regions, estates, owners, isLoading },
  } = useStores();

  const ownerOptions = owners?.map((owner) => ({
    id: String(owner.owner_id),
    label: owner.name,
  }));

  const estateOptions = estates?.map((estate) => ({
    id: String(estate.estate_id),
    label: estate.name,
  }));

  const regionOptions = regions?.map((region) => ({
    id: String(region.region_id),
    label: region.name,
  }));

  if (selectSingle) {
    ownerOptions?.unshift({ id: '', label: t('news.selectOwner') })
    regionOptions?.unshift({ id: '', label: t('news.selectRegion') })
    estateOptions?.unshift({ id: '', label: t('news.selectEstate') })
  }
  const renderOwnerValue = (val: string) => ownerOptions?.find((owner) => owner.id === val)?.label || '';

  const renderEstateValue = (val: string) => estateOptions?.find((estate) => estate.id === val)?.label || '';

  const renderRegionValue = (val: string) => regionOptions?.find((region) => region.id === val)?.label || '';

  const statusOptions = [{
    id: '', label: t('news.selectFilter'),
  }, {
    id: 'past', label: t('news.showPast'),
  }, {
    id: 'draft', label: t('news.showDraft'),
  }, {
    id: 'scheduled', label: t('news.showScheduled'),
  }];

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center"><CircularProgress /></Box>
    )
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        {statusFilter && (
          <SelectAdapter
            name="status"
            label={t('news.status')}
            options={statusOptions}
            displayEmpty
          />
        )}
        <SelectAdapter
          multiple={selectSingle ? false : true}
          name="owner"
          label={t('news.owner')}
          options={ownerOptions}
          displayEmpty={selectSingle}
          renderChipLabel={hideChips ? undefined : renderOwnerValue}
        />
      </Grid>

      <Grid item xs={4}>
        <SelectAdapter
          multiple={selectSingle ? false : true}
          name="region"
          label={t('news.region')}
          options={regionOptions}
          displayEmpty={selectSingle}
          renderChipLabel={hideChips ? undefined : renderRegionValue}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectAdapter
          multiple={selectSingle ? false : true}
          name="estate"
          label={t('news.estate')}
          options={estateOptions}
          displayEmpty={selectSingle}
          renderChipLabel={hideChips ? undefined : renderEstateValue}
        />
      </Grid>
    </Grid>
  );
});
