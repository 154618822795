import {
  makeObservable,
  computed,
  action,
  runInAction,
} from 'mobx';

import {
  getNews,
  getSingleNews,
  createNews,
  updateNews,
  deleteNews,
} from '../services/newsService';
import ToastStore from './ToastStore';
import { observable } from 'mobx';

interface INewsStore {
  state: StoreState;
  isLoading: boolean;
  news?: INews[];
  newsTotal: number;
  singleNews?: INews | INewsTemplate;
  getNews: (params: IGetAllNews) => void;
  getSingleNews: (id: string) => void;
  createNews: (news: INews) => void;
  updateNews: (news: INews) => void;
  deleteNews: (id: number) => void;
  resetNews: () => void;
}


class NewsModel implements INewsStore {
  state: StoreState = 'Idle';
  news: INewsStore['news'] = undefined;
  singleNews: INewsStore['singleNews'] = undefined;
  newsTotal: INewsStore['newsTotal'] = 0;

  constructor() {
    makeObservable(this, {
      isLoading: computed,
      getNews: action,
      getSingleNews: action,
      createNews: action,
      updateNews: action,
      resetNews: action,
      deleteNews: action,
      news: observable,
      singleNews: observable,
      setNewsFromTemplate: action,
      newsTotal: observable,
    });
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  setNewsFromTemplate = (template: INewsTemplate) => {
    runInAction(() => {
      this.singleNews = template;
    });
  };

  resetNews = () => {
    this.singleNews = undefined;
  };
  /**
   * Returns the news list
   */
  getNews = async (params: IGetAllNews) => {
    this.state = 'Loading';
    try {
      const response = await getNews(params);
      runInAction(() => {
        this.news = response.data.items;
        this.newsTotal = response.data.total;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Returns a single news by id
   */
  getSingleNews = async (id: string) => {
    this.state = 'Loading';
    try {
      const response = await getSingleNews(id);
      runInAction(() => {
        this.singleNews = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }


  createNews = async (news: INews) => {
    this.state = 'Loading';
    try {
      const res = await createNews(news);

      ToastStore.showSuccess('successes.news.createNews');
      runInAction(() => {
        this.state = 'Success';
      });
      return res;
    } catch (error) {
      ToastStore.showError('errors.news.createNews');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Update news
   */
  updateNews = async (news: INews) => {
    this.state = 'Loading';

    try {
      const res = await updateNews(news);
      ToastStore.showSuccess('successes.news.updateNews');
      runInAction(() => {
        this.state = 'Success';
      });
      return res;
    } catch (error) {
      ToastStore.showError('errors.news.updateNews');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Delete news
   */
  deleteNews = async (id: number) => {
    this.state = 'Loading';
    try {
      await deleteNews(id);
      ToastStore.showSuccess('successes.news.deleteNews');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.news.deleteNews');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }
}

const NewsStore = new NewsModel();

export default NewsStore;
