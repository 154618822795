/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableFooter, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { useFilters, usePagination, useSortBy, useTable, useRowSelect } from "react-table";
import FilterField from "./MaterialTableFilterField";
import TableHead from "./MaterialTableHead";
import Pagination from "../Pagination/Pagination";
import Spinner from "./MaterialTableSpinner";

let prevSelectedCount = 0;

const useStyles = makeStyles({
  spinner: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  pagination: {
    backgroundColor: "#fff",
  },
  container: (props) => ({
    flexGrow: "1",
    width: "unset",
    maxWidth: "100%",
    ...(props.maxWidth ? { width: "100%" } : {}),
    alignSelf: "flex-start",
    overflowX: "auto",
  }),
  table: (props) => ({
    width: "unset",
    maxWidth: "100%",
    ...(props.maxWidth ? { width: "100%" } : {}),
  }),
  root: {
    width: "100%",
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  unselectedRow: {
    "& > td:not(:first-child)": {
      opacity: 0.5,
    },
  },
});

const MaterialTable = ({
  data,
  columns,
  totalCount,
  onPageChange,
  onSortingChange,
  onFilteringChange,
  isLoading,
  footerRow,
  defaultPage,
  defaultPageSize,
  paginationEnabled,
  fixed,
  maxWidth,
  onRowSelect,
  onRemoveLastSelection,
  selectedIds,
  disabledIds,
}) => {
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: FilterField,
    }),
    []
  );

  const plugins = [];
  const afterPaginationPlugins = [];

  const getSelectedCheckboxesObj = () => {
    if (!selectedIds) return;
    const obj = {};
    selectedIds.map((id) => {
      const dataIndex = data.findIndex((item) => String(item.id) === String(id));
      obj[dataIndex] = true;
      return null;
    });

    return obj;
  };

  const initialState = {
    pageIndex: defaultPage,
    pageSize: defaultPageSize,
    selectedRowIds: getSelectedCheckboxesObj() || {},
  };

  if (onFilteringChange) {
    plugins.push(useFilters);
    initialState.filters = [];
  }

  if (onSortingChange) {
    plugins.push(useSortBy);
    initialState.sortBy = [];
  }

  if (onRowSelect) {
    afterPaginationPlugins.push(useRowSelect);
  }

  const classes = useStyles({ maxWidth });

  const onCheckboxClick = (e, onChange, checked, isToggleAll) => {
    if (onRemoveLastSelection) {
      if (((isToggleAll && !checked) || (!isToggleAll && prevSelectedCount === 1)) && !checked) {
        onRemoveLastSelection();
        return false;
      }
    }
    onChange(e);
  };

  const isDisabled = (id) => {
    return disabledIds ? disabledIds.includes(id) : false;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    state: { sortBy, pageIndex, pageSize, filters, selectedRowIds },
  } = useTable(
    {
      data,
      columns,
      manualSortBy: !!onSortingChange,
      manualPagination: true,
      manualFilters: !!onFilteringChange,
      pageCount: totalCount,
      autoResetPage: false,
      defaultColumn,
      initialState,
    },
    ...plugins,
    usePagination,
    ...afterPaginationPlugins,
    (hooks) => {
      if (!onRowSelect) return null;
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          style: {
            maxWidth: 70,
            width: 70,
          },
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => {
            const { onChange, ...rest } = getToggleAllRowsSelectedProps();
            return <Checkbox onChange={(e, checked) => onCheckboxClick(e, onChange, checked, true)} {...rest} />;
          },
          Cell: ({ row }) => {
            const { onChange, ...rest } = row.getToggleRowSelectedProps();
            return (
              <Checkbox
                disabled={isDisabled(row.id)}
                onChange={(e, checked) => onCheckboxClick(e, onChange, checked)}
                {...rest}
              />
            );
          },
        },
        ...columns,
      ]);
    }
  );

  /**
   * When sorting changes..
   */
  useEffect(() => {
    if (onSortingChange && sortBy.length) onSortingChange({ sortBy });
  }, [onSortingChange, sortBy]);

  /**
   * When page / row count changes ..
   */
  useEffect(() => {
    if (onPageChange) onPageChange(pageIndex, pageSize);
  }, [pageIndex, pageSize, onPageChange]);

  /**
   * When filters change ..
   */
  useEffect(() => {
    if (onFilteringChange) onFilteringChange(filters);
  }, [filters, onFilteringChange]);

  useLayoutEffect(
    (e) => {
      const selectedIndexes = Object.keys(selectedRowIds)
        .map((key) => {
          if (selectedRowIds[key]) {
            return parseInt(key, 10);
          }
          return null;
        })
        .filter((key) => key !== null)
        .filter((key) => !disabledIds || !disabledIds.includes(String(key)));

      const selectedIds = selectedIndexes.map((i) => String(data[i].id));

      if (onRowSelect && prevSelectedCount !== selectedIds.length) {
        prevSelectedCount = selectedIds.length;
        onRowSelect(selectedIds);
      }
    },
    [selectedRowIds, data, onRowSelect]
  );

  const style = fixed ? { tableLayout: "fixed" } : undefined;

  const getRowClassName = (row) => {
    if (!onRowSelect || (onRowSelect && row.isSelected)) return undefined;
    return classes.unselectedRow;
  };

  return (
    <div className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" {...getTableProps()} className={classes.table} style={style}>
          <TableHead
            onFilteringChange={onFilteringChange}
            onSortingChange={onSortingChange}
            headerGroups={headerGroups}
          />
          <TableBody {...getTableBodyProps()}>
            {isLoading && <Spinner />}
            {!isLoading &&
              rows.map((row) => {
                prepareRow(row);

                return (
                  <TableRow hover key={row.id} {...row.getRowProps()} className={getRowClassName(row)}>
                    {row.cells.map((cell) => (
                      <TableCell
                        key={`${cell.column.id}-${cell.row.id}`}
                        {...cell.getCellProps()}
                        style={cell.column.style}
                        width={cell.column.width}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
          {footerRow && (
            <TableFooter>
              <TableRow>
                {footerRow.map((value) => (
                  <TableCell>{value}</TableCell>
                ))}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {paginationEnabled ? (
        <Pagination
          totalCount={totalCount}
          page={pageIndex}
          pageSize={pageSize}
          gotoPage={gotoPage}
          className={classes.pagination}
        />
      ) : null}
    </div>
  );
};

MaterialTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onPageChange: PropTypes.func,
  onSortingChange: PropTypes.func,
  onFilteringChange: PropTypes.func,
  isLoading: PropTypes.bool,
  totalCount: PropTypes.number,
  defaultPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  paginationEnabled: PropTypes.bool.isRequired,
  fixed: PropTypes.bool,
  maxWidth: PropTypes.bool,
  onRowSelect: PropTypes.func,
  onRemoveLastSelection: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  disabledIds: PropTypes.arrayOf(PropTypes.string),
  footerRow: PropTypes.any,
};
MaterialTable.defaultProps = {
  data: [],
  columns: [],
  onPageChange: null,
  onSortingChange: null,
  onFilteringChange: null,
  isLoading: false,
  totalCount: 0,
  defaultPage: 0,
  defaultPageSize: 10,
  paginationEnabled: false,
  fixed: false,
  maxWidth: true,
  onRemoveLastSelection: null,
  onRowSelect: null,
  selectedIds: null,
  disabledIds: null,
  footerRow: null,
};

export default MaterialTable;
