import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Typography as T, Box } from '@material-ui/core';
import { Layout } from '../../components/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { CommonModal } from '../../components/Modals/CommonModal';
import { useStores } from '../../stores';
import { getPrettyDate } from '../../utils/i18nUtils';
import { FeedbackModal } from './FeedbackModal';
import { ActionTableCell } from '../../components/ActionTableCell/ActionTableCell';

export interface FeedbackScreenProps {

}

export const FeedbackScreen: FC<FeedbackScreenProps> = observer((props: FeedbackScreenProps) => {
  const PAGE_SIZE = 10;
  const {
    feedbackStore: { getSingleFeedback, getFeedbacks, deleteFeedback, feedbacks, singleFeedback, feedbacksTotal },
  } = useStores();

  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [deleteNewsModalOpen, setDeleteNewsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchFeedbacks = useCallback(async (pageIndex?: number) => {
    const page = pageIndex === undefined ? currentPage : pageIndex;
    const skip = PAGE_SIZE * page;
    return getFeedbacks({ take: PAGE_SIZE, skip });
  }, [getFeedbacks, currentPage]);

  useEffect(() => {
    if (!initialized) {
      fetchFeedbacks();
      setInitialized(true);
    }
  }, [fetchFeedbacks, initialized]);

  const handleDeleteClick = useCallback(() => (id: string) => {
    getSingleFeedback(id);
    setDeleteNewsModalOpen(true);
  }, [getSingleFeedback, setDeleteNewsModalOpen]);

  const handleEditClick = useCallback(() => (id: string) => {
    getSingleFeedback(id);
    setDetailsOpen(true);
  }, [getSingleFeedback, setDetailsOpen]);

  const handleDeleteConfirm = async () => {
    if (singleFeedback?.id) {
      await deleteFeedback(singleFeedback.id);
    }
    setDeleteNewsModalOpen(false);
    fetchFeedbacks();
  };

  const handleModalClose = () => {
    setDetailsOpen(false);
    setDeleteNewsModalOpen(false);
  }

  const onPageChange = async (pageIndex: number) => {
    if (pageIndex !== currentPage) {
      setCurrentPage(pageIndex);
      fetchFeedbacks(pageIndex);
    }
  };

  const COLUMNS = useMemo(() => {
    const cols = [{
      accessor: 'createddAt',
      Header: t('common.date'),
      Cell: ({ row: { original } }: any) => {
        return getPrettyDate(original.createdAt, true, 'klo');
      },
      width: '15%',
    }, {
      accessor: 'rating',
      width: '15%',
      Header: t('feedback.rating'),
    }, {
      accessor: 'firstName',
      Header: t('feedback.user.name'),
      width: '15%',
      Cell: ({ row: { original } }: any) => {
        return `${original.firstName || ''} ${original.lastName || ''}`;
      },
    }, {
      accessor: 'message',
      Header: t('feedback.message'),
      width: '70%',
    }, {
      accessor: 'id',
      Header: '',
      Cell: ({ cell }: any) => (
        <ActionTableCell
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          params={cell.value}
        />
      ),
    }];
    return cols;
  }, [handleEditClick, handleDeleteClick, t]);

  const renderDeleteNewsModal = () => (
    <CommonModal
      open={deleteNewsModalOpen}
      type="prompt"
      title={t('feedback.deleteFeedbackConfirm')}
      confirmText={t('common.remove')}
      onConfirm={handleDeleteConfirm}
      onCancel={handleModalClose}
    >
      <T>{t('feedback.deleteFeedbackConfirmBody')}</T>
    </CommonModal>
  );

  return (
    <Layout headerText={t('sidemenu.feedback')}>
      <Box>
        <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
          <T color="textSecondary">{t('feedback.feedbackCount', { count: feedbacksTotal })}</T>
        </Box>
        <MaterialTable
          columns={COLUMNS}
          data={feedbacks}
          onPageChange={onPageChange}
          totalCount={feedbacksTotal}
          paginationEnabled
        />
      </Box>
      {detailsOpen && (
        <FeedbackModal
          onClose={handleModalClose}
          item={singleFeedback}
        />
      )}
      {renderDeleteNewsModal()}
    </Layout>
  );
});
