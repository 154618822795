import {
  makeObservable,
  computed,
  action,
  runInAction,
} from 'mobx';

import {
  getUserList,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  updateUserPassword
} from '../services/userService';
import ToastStore from './ToastStore';
import { observable } from 'mobx';

interface IUserStore {
  state: StoreState;
  isLoading: boolean;
  users?: IAdminUser[];
  user?: IAdminUser;
  getUserList: () => void;
  getUser: (id: string) => void;
  createUser: (user: IAdminUser) => void;
  updateUser: (user: IAdminUser) => void;
  deleteUser: (id: number) => void;
}


class UserModel implements IUserStore {
  state: StoreState = 'Idle';
  users: IUserStore['users'] = undefined;
  user: IUserStore['user'] = undefined;

  constructor() {
    makeObservable(this, {
      isLoading: computed,
      getUserList: action,
      getUser: action,
      createUser: action,
      updateUser: action,
      deleteUser: action,
      updateUserPassword: action,
      users: observable,
      resetUser: action,
    });
  }

  resetUser = () => {
    this.user = undefined;
  };

  get isLoading() {
    return this.state === 'Loading';
  }

  /**
   * Returns the user list
   */
  getUserList = async () => {
    this.state = 'Loading';
    try {
      const response = await getUserList();
      runInAction(() => {
        this.users = response.data.results;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Returns a user by id
   */
  getUser = async (id: string) => {
    this.state = 'Loading';
    try {
      const response = await getUser(id);
      runInAction(() => {
        this.user = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  updateUserPassword = async (currentPassword: string, newPassword: string) => {
    this.state = 'Loading';
    try {
      await updateUserPassword(currentPassword, newPassword);
      ToastStore.showSuccess('successes.user.updateUserPassword');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.user.updateUserPassword');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }


  createUser = async (user: IAdminUser) => {
    this.state = 'Loading';
    try {
      await createUser(user);
      ToastStore.showSuccess('successes.user.createSuccess');
      runInAction(() => {
        this.getUserList();
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.user.createFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Update user
   */
  updateUser = async (user: IAdminUser) => {
    this.state = 'Loading';

    try {
      await updateUser(user);
      ToastStore.showSuccess('successes.user.updateSuccess');
      runInAction(() => {
        this.getUserList();
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.user.updateFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Delete user
   */
  deleteUser = async (id: number) => {
    this.state = 'Loading';
    try {
      await deleteUser(id);
      ToastStore.showSuccess('successes.user.deleteSuccess');
      runInAction(() => {
        this.getUserList();
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.user.deleteFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }
}

const UserStore = new UserModel();

export default UserStore;
