import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
// @ts-ignore
import { Redirect, Route, Switch } from 'react-router-dom';
import { useStores } from './stores';
import LoadingScreen from './screens/Loading/LoadingScreen';
import { PATHS } from './constants/routes';

const Routes = observer(() => {
  const {
    authStore: { isInitialized, isAuthLoading, refreshIsAuthenticated, currentUser },
    commonStore: { authToken, currentRoutes, updateCurrentRoutes },

  } = useStores();

  // learn how to properly do this to re-render on currentRouter change
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [counter, setCounter] = useState(0);

  // Refresh current user when authToken changes
  // - authToken is loaded from localStorage on page refresh
  // - authToken is cleared on logout
  useEffect(() => {
    refreshIsAuthenticated();
  }, [authToken, refreshIsAuthenticated]);

  // Update accessible routes when
  // - Current user is fully initialized on page reload
  // - User roles change when user logs in or out
  useEffect(() => {
    if (isInitialized) {
      updateCurrentRoutes();
      setCounter(new Date().getTime());
    }
  }, [isInitialized, updateCurrentRoutes, setCounter]);

  if (currentRoutes.length === 0 || isAuthLoading) return <LoadingScreen />;

  return (
    <Switch key={counter}>
      {currentRoutes.map((route) => {
        const Screen = route.component;
        return (
          <Route key={route.href} path={route.href} exact={route.exact}>
            <Screen />
          </Route>
        )
      })}
      <Redirect to={currentUser ? PATHS.news.main : PATHS.auth.login} />
    </Switch>
  );
});

export default Routes;
