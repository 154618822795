// @ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Box, InputLabel, } from '@material-ui/core';
import { toJS } from 'mobx';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface WysiwygProps {
  value?: any;
  onChange?: Function;
  label?: string;
  toolbar?: any;
}

export const Wysiwyg: FC<WysiwygProps> = ({ onChange, value, label, toolbar }) => {
  const [initialized, setInitialized] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState)
    return onChange && onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  useEffect(() => {
    if (!initialized) {
      if (value && typeof value === 'string') {
        const contentBlock = htmlToDraft(toJS(value));

        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          setEditorState(EditorState.createWithContent(contentState));
        }
        setInitialized(true);
      }
    }
  }, [value, setInitialized, initialized]);

  useEffect(() => {
    return () => {
      setInitialized(false);
      setEditorState(EditorState.createEmpty());
    }
  }, []);

  if (!toolbar) {
    toolbar = {
      options: ['inline', 'blockType', 'list', 'link'],
      blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2', 'H3', 'H4'],
      },
      inline: {
        options: ['bold', 'italic', 'underline'],
      },
    };
  }

  return (
    <Box width="100%">
      {label && (
        <Box mb={1}>
          <InputLabel>{label}</InputLabel>
        </Box>
      )}
      <Box bgcolor="#fff">
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbar={toolbar}
          stripPastedStyles
          wrapperStyle={{
          }}
          editorStyle={{
            minHeight: '400px',
            maxHeight: '500px',
            overflowY: 'auto',
            border: '1px solid #ddd',
            padding: '1rem',
            color: '#333'
          }}
        />
      </Box>
    </Box>
  );
};
