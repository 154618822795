import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, Box, CircularProgress } from '@material-ui/core';
import { useStores } from '../../stores/index';


interface MainSpinnerProps {

}

const useStyles = makeStyles({
  spinnerContainer: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '50%',
    zIndex: 3,
    width: 80,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 500,
    backgroundColor: 'rgba(0,0,0,0.8)',
  }
});
const MainSpinner: FC<MainSpinnerProps> = observer(() => {
  const [visible, setVisible] = useState(false);
  const {
    authStore: { isLoading: authStoreLoading },

    userStore: { isLoading: userStoreLoading, },

  } = useStores();

  const isSomeStoreLoading =
    authStoreLoading
    || userStoreLoading


  const classes = useStyles();

  useEffect(() => {
    if (isSomeStoreLoading) {
      setTimeout(() => {
        setVisible(true);
      }, 250);
    } else {
      setVisible(false);
    }
  }, [visible, setVisible, isSomeStoreLoading]);

  if (!isSomeStoreLoading || !visible) return null;

  return (
    <Box className={classes.spinnerContainer}>
      <CircularProgress />
    </Box>
  );
});

export default MainSpinner;

