import { Box, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import striptags from 'striptags';

interface HtmlContentCellProps {
  content: string;
  maxWidth: number;
}

const useStyles = makeStyles({
  cell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
});

export const HtmlContentCell: FC<HtmlContentCellProps> = ({ content, maxWidth }) => {
  const classes = useStyles();
  return (
    <Box className={classes.cell} maxWidth={maxWidth}>
      {striptags(content)}
    </Box>
  );
};
