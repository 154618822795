import { useState, useEffect } from 'react';
import { toJS, autorun, set } from 'mobx';
import groupBy from 'lodash.groupby';

/* eslint-disable no-param-reassign */
export const arrayMove = (arr: any[], old_index: number, new_index: number) => {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

/**
 * Checks if component is rendered by client / server
 */
export const useIsClient = () => {
  const [isClient, setClient] = useState(false);
  const key = isClient ? 'client' : 'server';

  useEffect(() => {
    setClient(true);
  }, []);

  return { isClient, key };
};

/**
 * Automatically saves store state into localStorage
 */
export const storeAutoSave = (_this: any, storeName: string, omittedProps?: string[]) => {
  const storedJson = localStorage.getItem(storeName)
  if (storedJson) {
    set(_this, JSON.parse(storedJson));
  }
  autorun(() => {
    const storeToSave = toJS(_this);
    if (omittedProps) {
      omittedProps?.forEach((prop) => {
        delete storeToSave[prop];
      });
    }
    localStorage.setItem(storeName, JSON.stringify(storeToSave))
  });
};

/**
 * Find item from array of objects
 * key defaults to 'id'
 */

export const findById = (list: any[], value: any, key?: string): any => {
  let item;
  for (let i = 0; i < list.length; i++) {
    if (list[i][key || 'id'] === value) {
      item = list[i];
      break;
    }
  }
  return item;
};

export const getGroupedItems = (
  items: any[],
  property: string,
) => {
  const groupObj = groupBy(items, (item: any) => {
    return property.split('.').reduce((a, b) => a[b], item);
  });

  return Object.keys(groupObj).map((name: string) => ({
    id: name,
    title: name,
    quantity: groupObj[name].length,
    children: groupObj[name],
  }));
};
