import { Box, Grid, makeStyles } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { LegacyInput as Input, LegacyButton as Button } from '../../components/FormControls';
import { composeValidators, isEmail, minLength, required } from '../../utils';

interface LoginFormProps {
  isLoading: boolean;
  onSubmit: (values: any) => void;
};

const useStyles = makeStyles({
  loginButton: {
    textTransform: 'uppercase',
    marginTop: '0.5rem',
    borderRadius: '50px',
    height: '3.4rem',
  },
});

const LoginForm: FC<LoginFormProps> = ({ isLoading, onSubmit }) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState();

  const onFormSubmit = (values: any, form: any, callback: any) => {
  };

  const onLoginClick = () => {
    onSubmit(formValues);
  };

  const onFormChange = ({ values }: any) => {
    setFormValues(values);
  };

  return (
    <Form
      onSubmit={onFormSubmit}
      render={({ handleSubmit, submitting, pristine, hasValidationErrors, ...rest }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box mt={3} />

            <Grid container spacing={1} justify="center">
              <Grid item xs={12}>
                <Input
                  id="email"
                  name="email"
                  t="common.email"
                  icon={<FontAwesomeIcon icon={faUser} />}
                  validate={composeValidators(required, isEmail)}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  t="common.password"
                  icon={<FontAwesomeIcon icon={faLock} />}
                  validate={composeValidators(required, minLength(8))}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.loginButton}
                  id="loginButton"
                  type="submit"
                  onClick={onLoginClick}
                  t="login.login"
                  fullWidth
                  loading={isLoading}
                  disabled={pristine || hasValidationErrors}
                />
              </Grid>
            </Grid>
            <FormSpy subscription={{ values: true }} onChange={onFormChange} />
          </form>
        )
      }}
    />
  );
};

export default LoginForm;
