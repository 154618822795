import { api, getPath, getAuthConfig } from './api';

const API_PATHS = {
  allItems: '/faq',
  allLists: '/faq/lists',
  singleList: '/faq/lists/{itemId}',
  single: '/faq/{itemId}',
  order: '/faq/order',
  slugs: '/faq/slugs',
}

export const getFaqItems = async (params?: IPaginationParams) => {
  return api.get(getPath(API_PATHS.allItems), getAuthConfig(false, params));
}

export const getFaqLists = async (params: IPaginationParams) => {
  return api.get(getPath(API_PATHS.allLists, getAuthConfig(false, params)));
}

export const getFaqList = async (slug: string) =>
  api.get(getPath(API_PATHS.singleList, slug), getAuthConfig());

export const getFaqSlugs = async () => {
  return api.get(getPath(API_PATHS.slugs), getAuthConfig());
}

export const getFaqItem = async (id: string) =>
  api.get(getPath(API_PATHS.single, id), getAuthConfig());

export const createFaqItem = async (data: IFaqItem) =>
  api.post(getPath(API_PATHS.allItems), data);

export const updateFaqItem = async (data: IFaqItem) =>
  api.put(getPath(API_PATHS.single, data.id), data, getAuthConfig());

export const deleteFaqItem = async (id: number) =>
  api.delete(getPath(API_PATHS.single, id), getAuthConfig());

export const createFaqList = async (data: IFaqList) =>
  api.post(getPath(API_PATHS.allLists), data);

export const updateFaqList = async (data: IFaqList) =>
  api.put(getPath(API_PATHS.singleList, data.id), data, getAuthConfig());

export const deleteFaqList = async (id: number) =>
  api.delete(getPath(API_PATHS.singleList, id), getAuthConfig());

export const updateFaqOrder = async (data: IUpdateFaqOrder) =>
  api.put(getPath(API_PATHS.order), data, getAuthConfig())
