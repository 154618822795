import { api, getPath, getAuthConfig } from './api';

const API_PATHS = {
  all: '/admin',
  get: '/admin/{itemId}',
  create: '/admin',
  update: '/admin/{itemId}',
  delete: '/admin/{itemId}',
  search: '/user/search',
  createEndUser: '/user',
  password: '/admin/me/password'
}

/**
 * User (Admins)
 */
export const getUserList = async (skip?: number, take?: number) => {
  return api.get(getPath(API_PATHS.all), getAuthConfig(false, { skip, take }));
}

export const getUser = async (id: string) =>
  api.get(getPath(API_PATHS.get, id), getAuthConfig());

export const createUser = async (data: IAdminUser) => {
  data.role = 'admin';
  return api.post(getPath(API_PATHS.create), data);
}
export const updateUser = async (data: IAdminUser) =>
  api.put(getPath(API_PATHS.update, data.id), data, getAuthConfig());

export const deleteUser = async (id: number) =>
  api.delete(getPath(API_PATHS.delete, id), getAuthConfig());

export const updateUserPassword = async (currentPassword: string, newPassword: string) =>
  api.put(getPath(API_PATHS.password), { currentPassword, newPassword }, getAuthConfig());
