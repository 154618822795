import axios from 'axios';
import errorHandler from './error-service';
import CommonStore from '../stores/CommonStore';


export let apiURL = process.env.REACT_APP_API_URL || 'https://yhkodit-asukassivusto-backend.stage.geniem.io';

/**
 * Helper for get'n  path URLs
 */
export const getPath = (path: string, itemId?: number | string, secondaryItemId?: number | string) => {
  return path
    .replace('{itemId}', String(itemId))
    .replace('{secondaryItemId}', String(secondaryItemId));
};

export const api = axios.create({
  baseURL: apiURL,
  timeout: 30000,
});

// Errors
if (api) {
  api.interceptors.response.use(
    (response) => response,
    errorHandler,
  );

  api.interceptors.request.use((config) => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const token = CommonStore.authToken;
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
}

export const getAuthConfig = (fileDownload?: boolean, params?: any): any => {
  const obj = {
    headers: {
      Authorization: `Bearer ${CommonStore.authToken}`,
    },
    responseType: 'json',
    params,
  };

  if (fileDownload) obj.responseType = 'arraybuffer';
  return obj;
};




/**
* Assets
*/
export const createAsset = async (name: string, content: any) => {
  const formData = new FormData();
  formData.append('file', content);
  formData.append('name', name);
  formData.append('preview', '');
  return api.post(getPath('asset'), formData, { headers: { 'content-type': 'multipart/form-data' } });
}
