import {
  makeObservable,
  computed,
  action,
  runInAction,
} from 'mobx';

import {
  getPageContents,
  getPageContent,
  createPageContent,
  updatePageContent,
  deletePageContent,
  getPageSlugs,
} from '../services/pageContentService';
import ToastStore from './ToastStore';
import { observable } from 'mobx';
import { createAsset } from '../services/api';
import axios from 'axios';

interface IPageContentStore {
  state: StoreState;
  isLoading: boolean;
  pageContents?: IPageContent[];
  pageContent?: IPageContent;
  pageContentTotal: number;
  slugs?: string[];
  getPageContents: (params: IPaginationParams) => void;
  getPageContent: (slug: string) => void;
  getPageSlugs: () => void;
  createPageContent: (PageContent: IPageContent) => void;
  updatePageContent: (PageContent: IPageContent) => void;
  deletePageContent: (id: number) => void;
}


class PageContentModel implements IPageContentStore {
  state: StoreState = 'Idle';
  pageContents: IPageContentStore['pageContents'] = undefined;
  pageContent: IPageContentStore['pageContent'] = undefined;
  slugs: IPageContentStore['slugs'] = undefined;
  pageContentTotal: IPageContentStore['pageContentTotal'] = 0;

  constructor() {
    makeObservable(this, {
      isLoading: computed,
      getPageContents: action,
      getPageContent: action,
      createPageContent: action,
      updatePageContent: action,
      deletePageContent: action,
      pageContents: observable,
      pageContent: observable,
      getPageSlugs: action,
      pageContentTotal: observable,
      resetPageContent: action,
    });
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  resetPageContent = () => {
    runInAction(() => {
      this.pageContent = undefined;
    })
  };

  /**
   * Returns the PageContent list
   */
  getPageContents = async (params: IPaginationParams) => {
    this.state = 'Loading';
    try {
      const response = await getPageContents(params);
      runInAction(() => {
        this.pageContents = response.data.items;
        this.pageContentTotal = response.data.total;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  getPageSlugs = async () => {
    this.state = 'Loading';
    try {
      const response = await getPageSlugs();
      runInAction(() => {
        this.slugs = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }


  /**
   * Returns a PageContent by slug
   */
  getPageContent = async (slug: string) => {
    this.state = 'Loading';
    try {
      const response = await getPageContent(slug);
      runInAction(() => {
        this.pageContent = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  createPageContent = async (pageContent: IPageContent) => {
    this.state = 'Loading';
    try {
      const data = {
        ...pageContent,
      };
      if (pageContent.newFeaturedAsset) {
        const name = `${pageContent.id}_${pageContent.newFeaturedAsset.name}`;
        const content = pageContent.newFeaturedAsset;
        const { data: featuredAsset } = await createAsset(name, content);
        data.featuredAsset = featuredAsset?.results || null;
        data.newFeaturedAsset = undefined;
      }

      await createPageContent(pageContent);

      ToastStore.showSuccess('successes.pageContent.createPageContent');
      runInAction(() => {
        this.getPageContents({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      let msg = 'errors.pageContent.createPageContent';
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          msg = 'errors.pageContent.duplicateSlug';
        }
      }
      ToastStore.showError(msg);
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Update PageContent
   */
  updatePageContent = async (pageContent: IPageContent) => {
    this.state = 'Loading';
    const data = {
      ...pageContent,
    };
    if (pageContent.newFeaturedAsset) {
      const name = `${pageContent.id}_${pageContent.newFeaturedAsset.name}`;
      const content = pageContent.newFeaturedAsset;
      const { data: featuredAsset } = await createAsset(name, content);
      data.featuredAsset = featuredAsset?.results || null;
      data.newFeaturedAsset = undefined;
    }

    try {
      await updatePageContent(pageContent);
      ToastStore.showSuccess('successes.pageContent.updatePageContent');
      runInAction(() => {
        this.getPageContents({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      let msg = 'errors.pageContent.updatePageContent';
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          msg = 'errors.pageContent.duplicateSlug';
        }
      }
      ToastStore.showError(msg);
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Delete PageContent
   */
  deletePageContent = async (id: number) => {
    this.state = 'Loading';
    try {
      await deletePageContent(id);
      ToastStore.showSuccess('successes.pageContent.deletePageContent');
      runInAction(() => {
        this.getPageContents({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.pageContent.deletePageContent');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }
}

const PageContentStore = new PageContentModel();

export default PageContentStore;
