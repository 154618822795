import React, { FC } from 'react';
import { Grid, Box, Typography, makeStyles, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
// @ts-ignore
import { useParams } from 'react-router-dom';
import { useStore } from '../../stores';
import { Layout } from '../../components/Layout';
import { Link } from '../../components/Link';
import ResetPasswordForm from './ResetPasswordForm';

interface ResetPasswordScreenProps {
}

const useStyles = makeStyles({
  fullHeight: {
    height: '100%',
    minHeight: '100vh',
  },
  center: {
    textAlign: 'center',
  },
  formContainer: {
    height: '100%',
    maxWidth: '960px',
    backgroundColor: '#000000',
  },
  imageContainer: {
    background: "url('/images/loginbg.png') no-repeat",
    height: '100%',
    minHeight: '100vh',
    maxWidth: '960px',
  },
});

export const ResetPasswordScreen: FC<ResetPasswordScreenProps> = observer(() => {
  const { resetPassword, isLoading } = useStore('authStore');
  const { userId: userIdParam, token: tokenParam }: { userId: string, token: string } = useParams();
  const { t } = useTranslation();
  const classes = useStyles();

  const onResetPasswordSubmit = (formValues: any, form: any, cb: Function) => {
    resetPassword({
      userId: userIdParam,
      token: tokenParam,
      password: formValues.password,
    });
  };

  return (
    <Layout>
      <Grid container justify="center" className={classes.fullHeight}>
        <Grid item xs={6} className={classes.formContainer}>
          <Grid container justify="center" alignItems="center" className={classes.fullHeight}>
            <Grid item xs={12} md={8} lg={6}>
              <Container>
                <Box mb={4} className={classes.center}>
                  <Typography variant="h4">{ t('login.boxManagement') }</Typography>
                </Box>
                <Box mb={4} className={classes.center}>
                  <Typography variant="h1">{ t('login.changePassword') }</Typography>
                </Box>
                <Box>
                  <ResetPasswordForm onSubmit={onResetPasswordSubmit} isLoading={isLoading} />
                </Box>
                <Box mt={4} className={classes.center}>
                  <Link to="/login">{ t('login.backToLogin') }</Link>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.imageContainer}>
          {' '}
        </Grid>
      </Grid>
    </Layout>
  );
});
