import { createMuiTheme } from '@material-ui/core';
import { fiFI } from '@material-ui/core/locale';
import { SkeletonClassKey } from '@material-ui/lab';
import colors from './colors';
import { darken } from 'polished';
import { PICKER_OVERRIDES } from './picker-overrides';

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiSkeleton: SkeletonClassKey;
  }
}

export const SPACING = [0, 8, 16, 24, 32, 48, 64, 128, 160, 192, 256];
export const FONT_SIZES = ['0.80rem', '0.85rem', '1rem', '1.125rem', '1.5rem', '2.25rem'];
const BORDER_RADIUS = '2px';

export const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Inter',
      'Verdana',
      'sans-serif',
    ].join(','),
    fontSize: 14,
    body1: {
      color: colors.text,
      fontSize: FONT_SIZES[1],
    },
    body2: {
      color: colors.text,
      fontSize: FONT_SIZES[0],
    },
    h1: {
      fontSize: FONT_SIZES[5], // 36px
      fontWeight: 700,
      colors: colors.primary,
    },
    h2: {
      fontSize: FONT_SIZES[4], // ${SPACING[3]px}
      fontWeight: 700,
      marginBottom: SPACING[2]
    },
    h3: {
      fontSize: FONT_SIZES[3], // 18px
      fontWeight: 'bold',
    },
    h4: {
      fontSize: FONT_SIZES[2], // 16px
      fontWeight: 'bold',
    },
    button: {
      fontSize: FONT_SIZES[3],
      fontWeight: 'normal',
      textTransform: 'none',
    }
  },
  spacing: SPACING,
  palette: {
    primary: {
      main: colors.text,
    },
    secondary: {
      main: colors.text,
    },
    text: {
      primary: colors.text,
      secondary: colors.black,
    }
  },
  props: {
    MuiButton: {
      variant: 'contained',
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: colors.black,
        '&$disabled, &$focused': {
          color: colors.black,
        }
      },
      filled: {
        color: colors.white,
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginLeft: `${SPACING[1]}px`,
        marginRight: 0,
      }
    },
    MuiTablePagination: {
      select: {
        display: 'none',
      }
    },
    MuiInputLabel: {
      outlined: {
        position: 'relative',
        transform: 'translate(14px, 36px) scale(1)',
        '&$shrink': {
          transform: 'none',
        },
      },
      filled: {
        color: colors.grey5,
      },
      formControl: {
        color: colors.black,
        transform: 'none',
        fontSize: 12,
        fontWeight: 600,
        '& + .MuiInput-formControl': {
          marginTop: '20px',
        },
      },
      shrink: {
        transform: 'none',
      }
    },

    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: 0,
        '& input': {
          paddingLeft: `${SPACING[1]}px`,
        },
      },
      root: {
        backgroundColor: 'transparent',
        borderColor: colors.grey8,
        border: '1px solid',
        borderRadius: 5,
        "&:hover $notchedOutline": {
          borderStyle: 'none',
        },
      },
      notchedOutline: {
        borderStyle: 'none',
      },
      input: {
        padding: `${SPACING[1]}px ${SPACING[2]}px`,
      }
    },
    MuiInput: {
      root: {
        color: colors.black,
        padding: `${SPACING[1]}px ${SPACING[2]}px`,
        backgroundColor: colors.white,
        borderRadius: BORDER_RADIUS,
        borderColor: colors.grey8,
        border: '1px solid',
        '&$focused, &:hover': {
          color: colors.black,
        },
        '&$disabled': {
          backgroundColor: '#fafafafa',
          color: colors.grey6,
          borderBottom: '1px solid',
        }
      },
      underline: {
        borderBottom: 'none',
      }
    },
    MuiFormGroup: {
      root: {
        flexDirection: 'row',
      }
    },
    MuiFormHelperText: {
      root: {
        color: colors.black,
      }
    },
    MuiButton: {
      root: {
        color: colors.text,
        padding: `0px ${SPACING[3]}px`,
        backgroundColor: colors.grey3,
        '&:hover': {
          backgroundColor: colors.grey5,
        },
        '&.Mui-disabled': {
          color: colors.grey5,
          backgroundColor: colors.grey3,
        },
      },
      text: {
        padding: `0px ${SPACING[3]}px`,
        borderRadius: BORDER_RADIUS,
        background: 'transparent',
        '& .MuiButton-label': {
          fontWeight: 400,
          fontSize: FONT_SIZES[0],
        },
        '&.Mui-disabled': {
          background: 'transparent',
          color: colors.grey5,
        },
      },
      contained: {
        padding: `0px ${SPACING[3]}px`,
        height: '40px',
        color: colors.white,
        backgroundColor: colors.secondary,
        '&:hover': {
          backgroundColor: darken(0.1, colors.secondary),
        },
        '&.Mui-disabled': {
          background: colors.grey5,
          color: 'white',
        },
      },
      outlined: {
        backgroundColor: 'transparent',
        borderColor: colors.grey5,
        '&:disabled': {
          backgroundColor: 'transparent',
          borderColor: colors.grey5
        }
      },
      label: {
        fontSize: FONT_SIZES[2],
        fontWeight: 600,
      }
    },
    MuiTableCell: {
      body: {
      },
      stickyHeader: {
        borderStyle: 'none',
        padding: '8px 16px',
        fontWeight: 600,
      },
      footer: {
        fontWeight: 600,
        borderBottom: 'none',
      }
    },
    MuiTableFooter: {

    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      }
    },
    MuiMenuItem: {
      root: {
        color: colors.black,
      },
    },
    MuiTab: {
      root: {
        fontSize: '0.875rem',
        minWidth: '0 !important',
        padding: '6px 0',
        marginRight: '12px',
        fontWeight: 600,
      }
    },
    MuiSelect: {
      iconOutlined: {
        color: colors.black,
      },
      outlined: {
        borderColor: colors.grey5,
        border: '1px solid',
        color: colors.black,
        backgroundColor: colors.background,
        padding: `${SPACING[1]}px ${SPACING[2]}px`,
        '&:hover, &:focus': {
          backgroundColor: colors.background,
        },

        '&.Mui-disabled': {
          color: colors.grey5,
          backgroundColor: colors.background,
        },
      }
    },
    MuiList: {
      root: {
        border: '1px solid #fff',
        borderRadius: 0,
      },

    },
    MuiListItem: {
      root: {
        '&.Mui-selected': {
          backgroundColor: colors.primary,
          color: colors.white,
        },
      },
    },
    MuiBreadcrumbs: {
      li: {
        '& a, & a p': {
          color: colors.black,
        },
        '& > p': {
          color: colors.grey8,
        },
      },
      separator: {
        color: colors.black,
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.grey5,
      }
    },
    MuiSwitch: {
      track: {
        backgroundColor: colors.grey5,
        opacity: 1,
      }
    },
    MuiCheckbox: {
      root: {
        color: colors.grey5,
      },
      colorSecondary: {
        '&.Mui-checked': {
          color: colors.primary2,
        },
        '&.Mui-disabled': {
          color: colors.grey3,

        }
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: colors.white,
        '&:focus': {
          outline: 'none',
        },
      }
    },
    MuiStepper: {
      root: {
        backgroundColor: colors.background,
        color: colors.grey8,
      }
    },
    MuiStepConnector: {
      line: {
        borderColor: colors.grey4,
      }
    },
    MuiStepLabel: {
      label: {
        fontSize: '14px',
        '&$active': {
          fontWeight: 600,
        },
      },
      iconContainer: {
        border: '1px solid',
        borderColor: colors.grey6,
        paddingRight: 0,
        marginRight: 12,
        borderRadius: 20,
        '&$active': {
          borderColor: colors.secondary,
        },
      },
    },
    MuiStepIcon: {
      text: {
        fill: colors.grey6,
      },
      root: {
        color: 'transparent',
        '&$active': {
          color: colors.secondary,
          '& text': {
            fill: colors.white,
          }
        }
      }
    },
    // @ts-ignore
    MuiAlert: {
      standardError: {
        backgroundColor: colors.red,
        color: colors.white,
      },
      standardSuccess: {
        backgroundColor: colors.green,
        color: colors.white,
      },
      icon: {
        color: '#fff !important',
      }
    },
    ...PICKER_OVERRIDES,
  },

}, fiFI);

export const spacing = (size: number) => {
  // @ts-ignore
  return theme.spacing * size;
}

export default theme;
