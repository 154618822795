import validator from 'validator';

export const composeValidators = (...validators: any[]) => (value: string | number) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (value: any) => (value ? undefined : 'validation.required');

export const allowEmpty = (validator: any) => (value: any) => (value ? validator(value) : undefined);

export const minLength = (minLength: number) => (value: string) => (value && value.length < minLength) ? 'validation.minLength' : undefined;

export const isEmail = (value: string) => value && validator.isEmail(value) ? undefined : 'validation.email';

const phoneNumberRegexp = /^\+{0,1}\d{7,14}$/;
export const isPhoneNumber = (value: string) => phoneNumberRegexp.test(value.replace(/ /g, '')) ? undefined : 'validation.phoneNumber';

const digitOrSpecialCharRegexp = /(?=.*[\d!"#€%&/=§°',\-;:_<>@±£©()[\]{}*+?|^$.\\])/;
export const hasDigitOrSpecialChar = (value: string) => digitOrSpecialCharRegexp.test(value) ? undefined : 'validation.digitOrSpecialChar';

const positiveNumberRegex = /^[0-9]+([.,][0-9]*)?$/;
export const isPositiveNumber = (value: string) => positiveNumberRegex.test(value) ? undefined : 'validation.mustBeAPositiveNumber';

const negativeNumberRegex = /^((0([.,]0*)?)|((-[0-9]+)([.,][0-9]*)?))$/;
export const isNegativeNumber = (value: string) => negativeNumberRegex.test(value) ? undefined : 'validation.mustBeANegativeNumber';

const numberRegex = /^[-]?[0-9]+([.,][0-9]*)?$/;
export const isNumber = (value: string) => numberRegex.test(value) ? undefined : 'validation.mustBeANumber';

