import {
  makeObservable,
  action,
  runInAction,
} from 'mobx';
import ToastStore from './ToastStore';
import { observable } from 'mobx';
import { getSettings, updateSettings } from '../services/settingsService';

interface ISettingsStore {
  settings?: ISettings;
  getSettings: () => void;
  updateSettings: (data: UpdateSettingsDTO) => void;
}


class SettingsModel implements ISettingsStore {
  state: StoreState = 'Idle';

  settings: ISettingsStore['settings'] = undefined;

  constructor() {
    makeObservable(this, {
      updateSettings: action,
      getSettings: action,
      settings: observable,
    });
  }

  get isLoading() {
    return this.state === 'Loading';
  }


  getSettings = async () => {
    this.state = 'Loading';
    try {
      const response = await getSettings();
      runInAction(() => {
        this.settings = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
        this.settings = {} as any;
      });
      throw error;
    }
  }


  updateSettings = async (data: UpdateSettingsDTO) => {
    this.state = 'Loading';
    try {
      const response = await updateSettings(data);
      runInAction(() => {
        this.settings = response.data;
        this.state = 'Success';
        ToastStore.showSuccess('successes.settings.updateSettings');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.settings.updateSettings');
        this.state = 'Error';
      });
      throw error;
    }
  }
}

const SettingsStore = new SettingsModel();

export default SettingsStore;
