import React, { FC, useEffect, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { ActionTableCell } from '../../components/ActionTableCell/ActionTableCell';
import { useStores } from '../../stores/index';
import { Box, Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import colors from '../../styles/colors';
import { HtmlContentCell } from '../../components/HtmlContentCell';

interface NewsTemplateTableProps {
  onEditClick?: any;
  onDeleteClick?: any;
  onOpenClick?: (template: INewsTemplate) => void;
  itemsEditable?: any;
};

export const NewsTemplateTable: FC<NewsTemplateTableProps> = observer(({
  onEditClick, onDeleteClick, itemsEditable, onOpenClick,
}) => {
  const { t } = useTranslation();
  const PAGE_SIZE = 10;

  const { newsTemplateStore: { getNewsTemplates, newsTemplates, newsTemplateTotal } } = useStores();
  const [currentPage, setCurrentPage] = useState(0);
  const [initialized, setInitialized] = useState(false);


  const fetchTemplates = useCallback(async (pageIndex?: number) => {
    const page = pageIndex === undefined ? currentPage : pageIndex;
    const skip = PAGE_SIZE * page;
    return getNewsTemplates({ take: PAGE_SIZE, skip });
  }, [getNewsTemplates, currentPage]);

  useEffect(() => {
    if (!initialized) {
      fetchTemplates();
      setInitialized(true);
    }
  }, [fetchTemplates, initialized]);

  const COLUMNS = useMemo(() => {
    const cols = [{
      accessor: 'title',
      Header: t('common.title'),
      Cell: ({ row: { original } }: any) => {
        return (
          <Box display="flex" alignItems="center">
            {original.isExceptionNotice && <Box mr={1}><FontAwesomeIcon color={colors.red} icon={faExclamationCircle} /></Box>}
            <Typography>{original.title}</Typography>
          </Box>
        );
      }
    }, {
      accessor: 'content',
      Header: t('common.content'),
      Cell: ({ row: { original } }: any) => {
        return <HtmlContentCell content={original.content} maxWidth={500} />
      },
    }, {
      accessor: 'id',
      Header: '',
      Cell: ({ cell, row: { original } }: any) => {
        if (itemsEditable) {
          return (
            <ActionTableCell
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              params={cell.value}
            />
          )
        } else if (onOpenClick) {
          return <Button variant="text" onClick={() => onOpenClick(original as INewsTemplate)}>{t('common.open')}</Button>
        }
      },
    }];
    return cols;
  }, [onEditClick, onDeleteClick, itemsEditable, onOpenClick, t]);


  const onPageChange = async (pageIndex: number) => {
    if (pageIndex !== currentPage) {
      setCurrentPage(pageIndex);
      fetchTemplates(pageIndex);
    }
  };

  return (
    <MaterialTable
      columns={COLUMNS}
      data={newsTemplates}
      onPageChange={onPageChange}
      totalCount={newsTemplateTotal}
      paginationEnabled
    />

  )


});
