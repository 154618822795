import {
  makeObservable,
  computed,
  action,
  runInAction,
} from 'mobx';

import {
  getFaqItems,
  getFaqItem,
  createFaqItem,
  updateFaqItem,
  deleteFaqItem,
  updateFaqOrder,
  getFaqLists,
  updateFaqList,
  deleteFaqList,
  getFaqList,
  createFaqList,
} from '../services/faqService';
import ToastStore from './ToastStore';
import { observable } from 'mobx';
import { getFaqSlugs } from '../services/faqService';
import axios from 'axios';



class FaqModel implements IFaqStore {
  state: StoreState = 'Idle';
  faqItems: IFaqStore['faqItems'] = undefined;
  faqLists: IFaqStore['faqLists'] = undefined;
  singleFaqItem: IFaqStore['singleFaqItem'] = undefined;
  singleFaqList: IFaqStore['singleFaqList'] = undefined;
  faqTotal: IFaqStore['faqTotal'] = 0;
  faqListTotal: IFaqStore['faqListTotal'] = 0;
  slugs: IFaqStore['slugs'] = undefined;

  constructor() {
    makeObservable(this, {
      isLoading: computed,
      getFaqItems: action,
      getFaqItem: action,
      getFaqLists: action,
      getFaqList: action,
      createFaqItem: action,
      updateFaqItem: action,
      deleteFaqItem: action,
      createFaqList: action,
      updateFaqList: action,
      deleteFaqList: action,
      updateFaqOrder: action,
      faqItems: observable,
      faqLists: observable,
      slugs: observable,
      singleFaqItem: observable,
      faqTotal: observable,
      singleFaqList: observable,
      faqListTotal: observable,
      resetSingleFaqItem: action,
      resetSingleFaqList: action,
      getSlugs: action,
    });
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  resetSingleFaqItem = () => {
    runInAction(() => {
      this.singleFaqItem = undefined;
    });
  }

  resetSingleFaqList = () => {
    runInAction(() => {
      this.singleFaqList = undefined;
    });
  }

  /**
 * Returns faq lists
 */
  getFaqLists = async (params: IPaginationParams) => {
    this.state = 'Loading';
    try {
      const response = await getFaqLists(params);
      runInAction(() => {
        this.faqLists = response.data.items;
        this.faqListTotal = response.data.total;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Returns a Faq list by sluyg
   */
  getFaqList = async (slug: string) => {
    this.state = 'Loading';
    try {
      const response = await getFaqList(slug);
      runInAction(() => {
        this.singleFaqList = response.data;
        this.state = 'Success';
      });
      return response;
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Returns the Faq item list
   */
  getFaqItems = async (params?: IPaginationParams) => {
    this.state = 'Loading';
    try {
      const response = await getFaqItems(params);
      runInAction(() => {
        this.faqItems = response.data.items;
        this.faqTotal = response.data.total;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Returns a Faq item by id
   */
  getFaqItem = async (id: string) => {
    this.state = 'Loading';
    try {
      const response = await getFaqItem(id);
      runInAction(() => {
        this.singleFaqItem = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  createFaqList = async (faqList: IFaqList) => {
    this.state = 'Loading';
    try {
      await createFaqList(faqList);

      ToastStore.showSuccess('successes.faq.createFaqList');
      runInAction(() => {
        this.getFaqLists({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      let msg = 'errors.faq.createFaqList';
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          msg = 'errors.faq.duplicateSlug';
        }
      }
      ToastStore.showError(msg);
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Update Faq item
   */
  updateFaqList = async (faqList: IFaqList) => {
    this.state = 'Loading';

    try {
      await updateFaqList(faqList);
      ToastStore.showSuccess('successes.faq.updateFaqList');
      runInAction(() => {
        this.getFaqLists({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      let msg = 'errors.faq.updateFaqList';
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          msg = 'errors.faq.duplicateSlug';
        }
      }
      ToastStore.showError(msg);
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Delete Faq item
   */
  deleteFaqList = async (id: number) => {
    this.state = 'Loading';
    try {
      await deleteFaqList(id);
      ToastStore.showSuccess('successes.faq.deleteFaqList');
      runInAction(() => {
        this.getFaqLists({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.faq.deleteFaqList');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  createFaqItem = async (FaqItem: IFaqItem) => {
    this.state = 'Loading';
    try {
      await createFaqItem(FaqItem);

      ToastStore.showSuccess('successes.faq.createFaqItem');
      runInAction(() => {
        this.getFaqItems({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.faq.createFaqItem');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Update Faq item
   */
  updateFaqItem = async (FaqItem: IFaqItem) => {
    this.state = 'Loading';

    try {
      await updateFaqItem(FaqItem);
      ToastStore.showSuccess('successes.faq.updateFaqItem');
      runInAction(() => {
        this.getFaqItems({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.faq.updateFaqItem');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Delete Faq item
   */
  deleteFaqItem = async (id: number) => {
    this.state = 'Loading';
    try {
      await deleteFaqItem(id);
      ToastStore.showSuccess('successes.faq.deleteFaqItem');
      runInAction(() => {
        this.getFaqItems({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.faq.deleteFaqItem');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  updateFaqOrder = async (data: IUpdateFaqOrder) => {
    this.state = 'Loading';
    try {
      await updateFaqOrder(data);
      ToastStore.showSuccess('successes.faq.updateFaqOrder');
      runInAction(() => {
        this.getFaqItems({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.faq.updateFaqOrder');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  getSlugs = async () => {
    this.state = 'Loading';
    try {
      const response = await getFaqSlugs();
      runInAction(() => {
        this.slugs = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }
}

const FaqStore = new FaqModel();

export default FaqStore;
