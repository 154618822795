import React, { FC } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Layout } from '../../components/Layout';

interface LoadingScreenProps {
}

const useStyles = makeStyles({
  fullHeight: {
    height: '100%',
  },
  center: {
    textAlign: 'center',
  },
});

const LoadingScreen: FC<LoadingScreenProps> = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Grid container justify="center" alignItems="center" className={classes.fullHeight}>
        <Grid item>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default LoadingScreen;
