import { Box, Button } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface FormFooterProps {
  onCancel: any;
  submitDisabled?: boolean;
  saveText?: string;
  cancelText?: string;
}

export const FormFooter: FC<FormFooterProps> = ({
  saveText, onCancel, submitDisabled, cancelText,
}) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" mt={3}>
      <Box mr={1}>
        <Button disabled={submitDisabled} type="submit">{t(saveText || 'common.save')}</Button>
      </Box>
      <Button onClick={onCancel} variant="outlined">{t(cancelText || 'common.cancel')}</Button>
    </Box>
  );
};
