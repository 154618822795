import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Typography, Box, makeStyles, Modal, ModalProps, Paper } from '@material-ui/core';
import theme from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export interface CommonModalProps extends ModalProps {
  type: 'prompt' | 'normal';
  title?: string;
  confirmText?: string;
  cancelText?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  footer?: ReactNode;
  hideClose?: boolean;
  width?: number;
}

export const CommonModal: FC<CommonModalProps> = observer(({
  open, footer, onCancel, onConfirm, confirmText, cancelText, children, title,
  onClose, type, width, ...rest
}) => {
  const { t } = useTranslation();

  const maxWidth = () => {
    if (width) {
      return width;
    }
    switch (type) {
      case 'prompt':
        return 600;
      case 'normal':
        return 1280;
      default:
        return 'auto';
    }
  }
  const useStyles = makeStyles({
    paper: {
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      minWidth: `min(90vw, ${maxWidth()}px)`,
      padding: theme.spacing(4),
      maxWidth: `min(90vw, ${maxWidth()}px)`,
    },
    footer: {
      marginTop: theme.spacing(4),
      '& > *': {
        marginRight: theme.spacing(1),
      }
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      marginBottom: 0,
    },
    mainContent: {
      maxHeight: '90vh',
    }
  });

  const classes = useStyles();

  if (!open) return null;

  return (
    <Modal {...rest} open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {(title || onClose) && (
          <Box mb={3} component="header" className={classes.header}>
            <Box>
              {title && <Typography className={classes.title} variant="h2">{title}</Typography>}
            </Box>
            {onClose && <Button variant="outlined" onClick={onClose}>{t('common.close')}</Button>}
          </Box>
        )}

        <Box className={classes.mainContent}>
          {children}
        </Box>
        {(onConfirm || onCancel) && (
          <Box className={classes.footer}>
            {onConfirm && <Button color="secondary" onClick={onConfirm} id="modal-confirm">{t(confirmText || 'common.confirm')}</Button>}
            {onCancel && <Button variant="outlined" onClick={onCancel} id="modal-cancel">{t(cancelText || 'common.cancel')}</Button>}
          </Box>
        )}
        {footer}
      </Paper>
    </Modal>
  );
});
