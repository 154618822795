/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import { FC, useState, useCallback, useEffect } from 'react'
import { DraggableItem } from './DraggableItem';
import update from 'immutability-helper'

const style = {

}

export interface Item {
  id: number
  text: string
}

export interface DraggableContainerState {
  items: Item[]
}

interface DraggableContainerProps {
  items?: any[];
  textAccessor: string;
  onChange: (items: any) => void;
}

export const DraggableContainer: FC<DraggableContainerProps> = ({ items: propItems, textAccessor, onChange }) => {
  {
    const [items, setItems] = useState(propItems || []);

    useEffect(() => {
      onChange(items);
    }, [items]);

    const moveItem = useCallback(
      (dragIndex: number, hoverIndex: number) => {
        const dragItem = items[dragIndex]
        setItems(
          update(items, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragItem],
            ],
          }),
        );
      },
      [items],
    )

    const renderItem = (item: any, index: number) => {
      return (
        <DraggableItem
          key={item.id}
          index={index}
          id={item.id}
          text={item[textAccessor]}
          moveItem={moveItem}
        />
      )
    }

    return (
      <>
        <div style={style}>{items.map((item, i) => renderItem(item, i))}</div>
      </>
    )
  }
}
