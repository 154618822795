import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction,
} from 'mobx';
import {
  login,
  getMe,
  updateMe,
  getResetPasswordEmail,
  resetPassword,
} from '../services/authService';
import CommonStore from './CommonStore';
import ToastStore from './ToastStore';
import axios from 'axios';


interface IAuthStore {
  state: StoreState;
  isLoading: boolean;
  isAuthLoading: boolean; // Authorized user is loading, used by router to determine when routes change
  isInitialized: boolean;
  currentUser?: IAdminUser;
  passwordChanged: boolean;
  login: (credentials: ILoginCredentials, cb: Function) => void;
  getCurrentUser: () => void;
  updateCurrentUser: (user: IAdminUser, cb?: Function) => void;
  logout: () => void;
  getResetPasswordEmail: (data: IGetResetPassword) => void;
  resetPassword: (data: IResetPassword) => void;
}

const handleLoginError = (error: any) => {
  let errorMsg = 'errors.user.loginFailed';
  if (error.response) {
    const { response: { config: { url } } } = error;

    if (url.includes('claimShoppingCart')) {
      errorMsg = 'errors.cart.claimCartFailed';
    }
  }
  ToastStore.showError(errorMsg);
};


class AuthModel implements IAuthStore {
  state: StoreState = 'Idle';
  isAuthLoading: IAuthStore['isAuthLoading'] = true;
  currentUser: IAuthStore['currentUser'] = undefined;
  isInitialized = false;
  passwordChanged = false;

  constructor() {
    makeObservable(this, {
      state: observable,
      isAuthLoading: observable,
      currentUser: observable,
      isInitialized: observable,
      passwordChanged: observable,
      isLoading: computed,
      login: action,
      updateCurrentUser: action,
      logout: action,
      getCurrentUser: action,
      refreshIsAuthenticated: action,
      getResetPasswordEmail: action,
      resetPassword: action,
    });
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  /**
   * Logs the user in
   */
  login = async (credentials: ILoginCredentials,) => {
    this.state = 'Loading';
    this.isAuthLoading = true;

    try {
      const response = await login(credentials);
      runInAction(() => {
        this.state = 'Success';
        this.currentUser = response.data.user;
        this.isAuthLoading = false;
        CommonStore.updateAuthToken(response.data.token);
        ToastStore.showSuccess('successes.user.loginSuccess', { email: credentials.email });
        window.location.reload();
      });

    } catch (error) {
      handleLoginError(error);
      runInAction(() => {
        this.state = 'Error';
        this.isAuthLoading = false;
      });
    }
  }

  /**
   * Updates the current user
   */
  updateCurrentUser = async (user: IAdminUser, cb?: Function) => {
    this.state = 'Loading';

    try {
      await updateMe(user);
      runInAction(() => {
        this.state = 'Success';
        this.getCurrentUser();
      });
      ToastStore.showSuccess('successes.informationUpdated');
    } catch (error) {
      if (cb) cb(error);
      ToastStore.showError('errors.user.updateFailed');
      runInAction(() => {
        this.state = 'Error';
      });
    }
  }

  /**
   * Logs the user out (i.e. deletes the token)
   */
  logout = () => {
    runInAction(() => {
      CommonStore.updateAuthToken('');
      this.currentUser = undefined;
      window.location.reload();
    });
  };

  /**
   * Returns the current user
   */
  getCurrentUser = async () => {
    this.state = 'Loading';
    try {
      const response = await getMe();
      runInAction(() => {
        this.currentUser = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      ToastStore.showError('errors.user.getCurrentUserFailed');
      throw error;
    }
  }

  /**
   * Refreshes current user if authToken is present
   */
  refreshIsAuthenticated = async () => {
    if (CommonStore.authToken) {
      this.isAuthLoading = true;
      await this.getCurrentUser();
      runInAction(() => {
        this.isInitialized = true;
        this.isAuthLoading = false;
      });
    } else {
      this.currentUser = undefined;
      this.isInitialized = true;
      this.isAuthLoading = false;
    }
  };

  /**
   * Triggers password reset email to be sent into specified email address
   */
  getResetPasswordEmail = async (data: IGetResetPassword) => {
    this.state = 'Loading';
    try {
      await getResetPasswordEmail(data);
      runInAction(() => {
        this.state = 'Success';
      });
      ToastStore.showSuccess('successes.user.passwordResetInstructionsSent');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data && error.response.data.message === 'User not found') {
          ToastStore.showError('errors.user.resetPasswordUserNotFound');
        }
      }
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Resets current password
   */
  resetPassword = async (data: IResetPassword) => {
    this.state = 'Loading';
    try {
      await resetPassword(data);
      runInAction(() => {
        this.state = 'Success';
        this.passwordChanged = true;
      });
      ToastStore.showSuccess('successes.user.passwordReset');
    } catch (error) {
      let msg = 'errors.user.passwordResetFailed';

      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data && error.response.data.message === '"Expired or bad token') {
          msg = 'errors.user.passwordResetFailedExpired';
        }
      }
      runInAction(() => {
        this.state = 'Error';
      });

      ToastStore.showError(msg);
      throw error;
    }
  }
}

const AuthStore = new AuthModel();

export default AuthStore;
