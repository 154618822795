import * as Yup from 'yup';
import i18n from 'i18next';
import { AnyObject, Maybe } from "yup/lib/types";
import validator from 'validator';
import { initLang } from '../i18n/index';

initLang('fi');




Yup.addMethod(Yup.mixed, 'optional', function optional() {
  return this.transform((value) => {
    return (
      (typeof value == 'string' && !value)
      || (value instanceof Array && !value.length)
      || (value === null) // allow to skip "nullable"
    )
      ? undefined
      : value
  })
});

Yup.addMethod(Yup.string, 'hasUpperCase', function hasUpperCase() {
  const testFn = (value: string) => {
    if (value === undefined) return undefined;
    return (/[A-Z]/.test(value));
  };
  return this.test('test-hasUpperCase', 'test', function (value) {
    const { path, createError } = this;
    if (!value || testFn(value)) {
      return true;
    }
    return createError({
      path, message: i18n.t('validation.hasUpperCase'),
    });
  });
});

Yup.addMethod(Yup.string, 'hasLowerCase', function hasLowerCase() {
  const testFn = (value: string) => {
    if (value === undefined) return undefined;
    return (/[a-z]/.test(value));
  };

  return this.test('test-hasLowerCase', 'test', function (value) {
    const { path, createError } = this;
    if (!value || testFn(value)) {
      return true;
    }
    return createError({
      path, message: i18n.t('validation.hasLowerCase'),
    });
  });
});


Yup.addMethod(Yup.string, 'hasNumber', function hasNumber() {
  const testFn = (value: string) => {
    if (value === undefined) return undefined;
    return /\d/.test(value);
  };

  return this.test('test-hasNumber', 'test', function (value) {
    const { path, createError } = this;
    if (!value || testFn(value)) {
      return true;
    }
    return createError({
      path, message: i18n.t('validation.hasNumber'),
    });
  });
});

Yup.addMethod(Yup.string, 'hasSpecialChars', function hasSpecialChars() {
  const testFn = (value: string) => {
    if (value === undefined) return undefined;
    return /[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(value);
  };

  return this.test('test-hasSpecialChars', 'test', function (value) {
    const { path, createError } = this;
    if (!value || testFn(value)) {
      return true;
    }
    return createError({
      path, message: i18n.t('validation.hasSpecialChars'),
    });
  });
});

/**
 * Phone number validator
 */
Yup.addMethod(Yup.string, 'phoneNumber', function numberString() {
  return this.test('test-name', 'test', function (value) {
    const { path, createError } = this;
    if (!value || validator.isMobilePhone(value)) {
      return true;
    }
    return createError({
      path, message: i18n.t('validation.phoneNumber'),
    });
  });
});

/**
 * Number string validator
 */
Yup.addMethod(Yup.string, 'numberString', function numberString() {
  return this.test('test-name', 'test', function (value) {
    const { path, createError } = this;
    if (!value || validator.isNumeric(value || '')) {
      return true;
    }
    return createError({
      path, message: i18n.t('validation.numberString'),
    });
  });
});


Yup.setLocale({
  mixed: {
    required: i18n.t('validation.required'),
  },
  string: {
    email: i18n.t('validation.email'),
    min: ({ min }: { min: number }) => i18n.t('validation.minLength', { min }),
    max: ({ max }: { max: number }) => i18n.t('validation.maxLength', { max }),
    length: ({ length }: { length: number }) => i18n.t('validation.length', { length }),
  },
  number: {
    default: i18n.t('validation.mustBeANumber'),
    integer: i18n.t('validation.mustBeANumber'),
    positive: i18n.t('validation.mustBeAPositiveNumber'),
    min: ({ min }: { min: number }) => i18n.t('validation.minValue', { min }),
    max: ({ max }: { max: number }) => i18n.t('validation.maxValue', { max }),
  },
});

export { Yup as Validator }


// Add custom methods to yup type
declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
    > extends Yup.BaseSchema<TType, TContext, TOut> {
    phoneNumber(): StringSchema<TType, TContext>;
    numberString(): StringSchema<TType, TContext>;
    hasNumber(): StringSchema<TType, TContext>;
    hasUpperCase(): StringSchema<TType, TContext>;
    hasLowerCase(): StringSchema<TType, TContext>;
    hasSpecialChars(): StringSchema<TType, TContext>;
  }
}
