import { api, getPath, getAuthConfig } from './api';

const API_PATHS = {
  all: '/news/allNews',
  get: '/news/{itemId}',
  create: '/news',
  update: '/news/{itemId}',
  delete: '/news/{itemId}',
}

const toString = (arr: string[]) => {
  return arr.filter(val => val).join(',');
};

const prepareNewsValues = (newsObj: INews) => {
  let newValues = { ...newsObj };
  if (!newValues.isDraft) newValues.isDraft = false;
  if (!newValues.isExceptionNotice) newValues.isExceptionNotice = false;
  if (Array.isArray(newValues.owner)) newValues.owner = toString(newValues.owner);
  if (Array.isArray(newValues.region)) newValues.region = toString(newValues.region);
  if (Array.isArray(newValues.estate)) newValues.estate = toString(newValues.estate);

  if (!newValues.estate) newValues.estate = undefined;
  if (!newValues.owner) newValues.owner = undefined;
  if (!newValues.region) newValues.region = undefined;

  return newValues;
};

export const getNews = async (params: IGetAllNews) => {
  return api.get(getPath(API_PATHS.all), getAuthConfig(false, params));
}

export const getSingleNews = async (id: string) =>
  api.get(getPath(API_PATHS.get, id), getAuthConfig());

export const createNews = async (newsData: INews) => {
  const data = prepareNewsValues(newsData);
  return api.post(getPath(API_PATHS.create), data);
}
export const updateNews = async (newsData: INews) => {
  const data = prepareNewsValues(newsData);
  return api.put(getPath(API_PATHS.update, data.id), data, getAuthConfig());
}
export const deleteNews = async (id: number) =>
  api.delete(getPath(API_PATHS.delete, id), getAuthConfig());
