import { api, getPath, getAuthConfig } from './api';

const API_PATHS = {
  register: '/admin',
  login: '/auth/adminLogin',
  me: '/admin/me',
  resetPasswordRequest: '/admin/passwordReset/reset',
  resetPassword: '/admin/passwordReset',
}
/**
 * Auth
 */
export const login = async (credentials: ILoginCredentials) => {
  return api.post(API_PATHS.login, credentials);
};

export const getMe = async () =>
  api.get(getPath(API_PATHS.me), getAuthConfig());

export const updateMe = async (user: IAdminUser) =>
  api.put(getPath(API_PATHS.me), user, getAuthConfig());

export const getResetPasswordEmail = async (data: IGetResetPassword) =>
  api.post(getPath(API_PATHS.resetPassword), data);

export const resetPassword = async (data: IResetPassword) =>
  api.post(getPath(API_PATHS.resetPasswordRequest), data);
