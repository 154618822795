import { Box, Grid, Typography as T } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import {
  TextField,
  makeValidate,
  makeRequired,
} from 'mui-rff';
import { Validator } from '../../utils/validation';
import { MINIMUM_PASSWORD_LENGTH } from '../../constants/siteSettings';
import { CommonModal } from '../../components/Modals/CommonModal';
import { FormFooter } from '../../components/FormControls/FormFooter';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../stores/index';

interface UserModalProps {
  isOpen: boolean;
  item?: IAdminUser;
  handleClose: () => void;
};

export const UserModal: FC<UserModalProps> = observer(({ item, handleClose, isOpen }) => {
  const { t } = useTranslation();
  const { userStore: { updateUser, createUser } } = useStores();
  const schema = Validator.object().shape({
    firstName: Validator.string().max(255).required(),
    lastName: Validator.string().max(255).required(),
    email: Validator.string().email().max(255).required(),
    password: item ? Validator.string() : Validator
      .string()
      .hasSpecialChars()
      .hasLowerCase()
      .hasUpperCase()
      .hasNumber()
      .min(MINIMUM_PASSWORD_LENGTH)
      .required(),

  });

  const validate = makeValidate(schema);
  const required = makeRequired(schema);

  const onSubmit = async (values: any) => {
    if (item) {
      await updateUser(values);
    } else {
      await createUser(values)
    }
    handleClose();
  };

  return (
    <CommonModal open={isOpen} type="normal">
      <Form
        initialValues={item}
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit, values, submitting, pristine, hasValidationErrors, form: { change }, ...rest }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container>
                <Grid item >
                  <Box>
                    <Grid spacing={3} container>
                      <Grid item xs={12}>
                        <Box>
                          <T variant="h2">{t(item ? 'user.editUser' : 'user.addNewUser')}</T>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="firstName"
                          name="firstName"
                          label={t('user.firstName')}
                          required={required.firstName}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="lastName"
                          name="lastName"
                          label={t('user.lastName')}
                          required={required.lastName}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="email"
                          name="email"
                          label={t('user.email')}
                          required={required.email}
                        />
                      </Grid>
                      {!item && (
                        <Grid item xs={12}>
                          <TextField
                            id="password"
                            type="password"
                            name="password"
                            label={t('user.password')}
                            required={required.password}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <FormFooter onCancel={handleClose} submitDisabled={hasValidationErrors} />
            </form>
          )
        }}
      />
    </CommonModal>
  );
});
