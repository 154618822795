import {
  makeObservable,
  computed,
  action,
  runInAction,
} from 'mobx';

import {
  getNewsTemplates,
  getNewsTemplate,
  createNewsTemplate,
  updateNewsTemplate,
  deleteNewsTemplate,
} from '../services/newsTemplateService';
import ToastStore from './ToastStore';
import { observable } from 'mobx';

interface INewsTemplateStore {
  state: StoreState;
  isLoading: boolean;
  newsTemplates?: INewsTemplate[];
  newsTemplate?: INewsTemplate;
  newsTemplateTotal: number;
  getNewsTemplates: (params?: IPaginationParams) => void;
  getNewsTemplate: (id: string) => void;
  createNewsTemplate: (newsTemplate: INewsTemplate) => void;
  updateNewsTemplate: (newsTemplate: INewsTemplate) => void;
  deleteNewsTemplate: (id: number) => void;
  resetNewsTemplate: () => void;
}


class NewsTemplateModel implements INewsTemplateStore {
  state: StoreState = 'Idle';
  newsTemplates: INewsTemplateStore['newsTemplates'] = undefined;
  newsTemplate: INewsTemplateStore['newsTemplate'] = undefined;
  newsTemplateTotal: INewsTemplateStore['newsTemplateTotal'] = 0;

  constructor() {
    makeObservable(this, {
      isLoading: computed,
      getNewsTemplates: action,
      getNewsTemplate: action,
      createNewsTemplate: action,
      updateNewsTemplate: action,
      deleteNewsTemplate: action,
      newsTemplates: observable,
      newsTemplate: observable,
      newsTemplateTotal: observable,
      resetNewsTemplate: action,
    });
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  resetNewsTemplate = () => {
    runInAction(() => {
    this.newsTemplate = undefined;
    });
  }
  /**
   * Returns the NewsTemplate list
   */
  getNewsTemplates = async (params?: IPaginationParams) => {
    this.state = 'Loading';
    try {
      const response = await getNewsTemplates(params);
      runInAction(() => {
        this.newsTemplates = response.data.items;
        this.newsTemplateTotal = response.data.total;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Returns a NewsTemplate by id
   */
  getNewsTemplate = async (id: string) => {
    this.state = 'Loading';
    try {
      const response = await getNewsTemplate(id);
      runInAction(() => {
        this.newsTemplate = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  createNewsTemplate = async (newsTemplate: INewsTemplate) => {
    this.state = 'Loading';
    try {
      await createNewsTemplate(newsTemplate);

      ToastStore.showSuccess('successes.newsTemplate.createNewsTemplate');
      runInAction(() => {
        this.getNewsTemplates({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.newsTemplate.createNewsTemplate');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Update NewsTemplate
   */
  updateNewsTemplate = async (newsTemplate: INewsTemplate) => {
    this.state = 'Loading';

    try {
      await updateNewsTemplate(newsTemplate);
      ToastStore.showSuccess('successes.newsTemplate.updateNewsTemplate');
      runInAction(() => {
        this.getNewsTemplates({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.newsTemplate.updateNewsTemplate');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }

  /**
   * Delete NewsTemplate
   */
  deleteNewsTemplate = async (id: number) => {
    this.state = 'Loading';
    try {
      await deleteNewsTemplate(id);
      ToastStore.showSuccess('successes.newsTemplate.deleteNewsTemplate');
      runInAction(() => {
        this.getNewsTemplates({ take: 10, skip: 0 });
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.newsTemplate.deleteNewsTemplate');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  }
}

const NewsTemplateStore = new NewsTemplateModel();

export default NewsTemplateStore;
