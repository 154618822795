import React from 'react';
import { LoginScreen, RequestResetPasswordScreen, ResetPasswordScreen } from '../screens/Login';
import { UsersScreen } from '../screens/Users';
import { NewsScreen, NewsTemplateScreen } from '../screens/News';
import { FeedbackScreen } from '../screens/Feedback';
import { ChecklistScreen } from '../screens/Checklist';
import { SettingsScreen, LinksScreen } from '../screens/Settings/';
import { FaqScreen, FaqListsScreen } from '../screens/Faq';
import { PageContentScreen } from '../screens/PageContent/PageContentScreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers, faNewspaper, faComment, faCheckSquare, faQuestion, faFile, faCog,
} from '@fortawesome/free-solid-svg-icons';

export const PATHS = {
  users: {
    main: '/users',
  },
  news: {
    main: '/news',
    templates: '/news/templates'
  },
  feedback: {
    main: '/feedback'
  },
  checklist: {
    main: '/checklist'
  },
  pages: {
    main: '/pages'
  },
  faq: {
    main: '/faq',
    items: '/faq/items',
  },
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    passwordReset: '/password-reset/:userId/:token',
  },
  settings: {
    main: '/settings',
    links: '/settins/links'
  },
  links: {
    main: '/links'
  }
};

/**
 * AUTH
 */
const login: IRoute[] = [{
  name: 'login',
  href: PATHS.auth.login,
  component: LoginScreen,
  nonAuth: true,
}, {
  name: 'forgot-password',
  href: PATHS.auth.forgotPassword,
  component: RequestResetPasswordScreen,
  nonAuth: true,
}, {
  name: 'password-reset',
  href: PATHS.auth.passwordReset,
  component: ResetPasswordScreen,
  nonAuth: true,
}];


/**
 * USERS
 */
const users: IRoute[] = [{
  menuGroup: 'user',
  name: 'users',
  href: PATHS.users.main,
  component: UsersScreen,
  nonAuth: false,
  menuItem: true,
  iconComponent: <FontAwesomeIcon icon={faUsers} />,
}];

/**
 * NEWS
 */
const news: IRoute[] = [{
  menuGroup: 'news',
  name: 'news',
  href: PATHS.news.main,
  component: NewsScreen,
  nonAuth: false,
  menuItem: true,
  exact: true,
  iconComponent: <FontAwesomeIcon icon={faNewspaper} />,
}, {
  menuGroup: 'news',
  name: 'newsTemplates',
  href: PATHS.news.templates,
  component: NewsTemplateScreen,
  nonAuth: false,
  menuItem: true,
  subItem: true,
  exact: true,
}];

/**
 * FEEDBACKS
 */
const feedbacks: IRoute[] = [{
  menuGroup: 'feedback',
  name: 'feedback',
  href: PATHS.feedback.main,
  component: FeedbackScreen,
  nonAuth: false,
  menuItem: true,
  iconComponent: <FontAwesomeIcon icon={faComment} />,
}];

const checklist: IRoute[] = [{
  menuGroup: 'checklist',
  name: 'checklist',
  href: PATHS.checklist.main,
  component: ChecklistScreen,
  nonAuth: false,
  menuItem: true,
  iconComponent: <FontAwesomeIcon icon={faCheckSquare} />,
}];


const faq: IRoute[] = [{
  menuGroup: 'faq',
  name: 'faq',
  href: PATHS.faq.main,
  component: FaqListsScreen,
  nonAuth: false,
  menuItem: true,
  exact: true,
  iconComponent: <FontAwesomeIcon icon={faQuestion} />,
}, {
  menuGroup: 'faq',
  name: 'faqItems',
  href: PATHS.faq.items,
  component: FaqScreen,
  nonAuth: false,
  menuItem: true,
  subItem: true,
  exact: true,
}];

const pageContent: IRoute[] = [{
  menuGroup: 'pageContent',
  name: 'pageContent',
  href: PATHS.pages.main,
  component: PageContentScreen,
  nonAuth: false,
  menuItem: true,
  iconComponent: <FontAwesomeIcon icon={faFile} />,
}];

const settings: IRoute[] = [{
  menuGroup: 'settings',
  name: 'settings',
  href: PATHS.settings.links,
  component: LinksScreen,
  nonAuth: false,
  menuItem: true,
  iconComponent: <FontAwesomeIcon icon={faCog} />,
  exact: true,
},
{
  menuGroup: 'settings',
  name: 'maintenance',
  href: PATHS.settings.main,
  component: SettingsScreen,
  nonAuth: false,
  menuItem: true,
  subItem: true,
  iconComponent: <FontAwesomeIcon icon={faCog} />,
}
];

export const ROUTES: IRoute[] = [
  ...login,
  ...users,
  ...news,
  ...pageContent,
  ...feedbacks,
  ...checklist,
  ...faq,
  ...settings,
];

